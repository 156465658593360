<div class="modal__container" data-cy="cancel-object-modal">
    <div class="modal__title">
        <h2 class="modal__title-text mb-100" data-cy="headline-modal">{{modalContentCaption}}</h2>
        <button type="button" class="icon-btn icon-btn--50 modal__close" (click)="closeModal.emit()" data-cy="close-modal-button" [disabled]="cancellationInProgress">
            <span class="sd-icon">close</span>
        </button>
    </div>
    <div class="modal__content">
        <div class="modal__content-inner">
            <p class="text-100 is-bold mb-100">{{modalContentSubCaption}}</p>
            <p class="has-font-100 info__text mt-100"data-cy="cancel-subscription-info">{{modalContentText}}</p>
            <app-alertbox
                *ngIf="subscriptionError"
                [alertText]="AlertText.SubscriptionWarning"
                [alertType]="AlertType.Warning"
                [alertIcon]="Icon.Exclamation">
            </app-alertbox>
        </div>
    </div>
    <div class="modal__buttons">
        <sd-button *ngIf="!subscriptionCancelProcessDone" (click)="closeModal.emit()" [variant]="'tertiary'" [disabled]="cancellationInProgress" data-cy="abort-button">Abbrechen</sd-button>
        <sd-button #cancellationButton *ngIf="!subscriptionCancelProcessDone" (click)="onCancelClick()" [loading]="cancellationInProgress" data-cy="cancel-subscription">Jetzt kündigen</sd-button>
        <sd-button *ngIf="subscriptionCancelProcessDone" (click)="closeModal.emit()">Verstanden</sd-button>
    </div>
</div>
