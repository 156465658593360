import { Injectable } from '@angular/core';
import { Context } from '@datadog/browser-core';
import { datadogLogs, StatusType } from '@datadog/browser-logs';
import jwt_decode from 'jwt-decode';
import { environment } from '@environments/environment';
import { CookieService } from './cookie.service';

@Injectable({ providedIn: 'root' })
export class DatadogService {
  constructor(private cookieService: CookieService) {}

  public logError(error: string, context?: Context | undefined): void {
    context = this.addCustomParametersToContext(context);
    datadogLogs.logger.error(error, context);
  }

  public logInfo(info: string, context?: Context | undefined): void {
    context = this.addCustomParametersToContext(context);
    datadogLogs.logger.info(info, context);
  }

  public logWarning(warning: string, context?: Context | undefined): void {
    context = this.addCustomParametersToContext(context);
    datadogLogs.logger.warn(warning, context);
  }

  public logDebug(debug: string, context?: Context | undefined): void {
    context = this.addCustomParametersToContext(context);
    datadogLogs.logger.debug(debug, context);
  }

  public init(): void {
    if (window.location.hostname) {
      datadogLogs.init({
        clientToken: 'puba5a3551b2595f26a21cd521c8fbf43cc',
        forwardErrorsToLogs: true,
        site: 'datadoghq.eu',
        sampleRate: 100
      });

      datadogLogs.addLoggerGlobalContext('host', window.location.hostname);
      datadogLogs.addLoggerGlobalContext('service', 'home-of-owners-ui');
      datadogLogs.addLoggerGlobalContext('co.stage', environment.stage);
      datadogLogs.addLoggerGlobalContext('co.team', 'skunkworks');

      type LogLevelStrings = keyof typeof StatusType;
      const key: LogLevelStrings = environment.logLevel as LogLevelStrings;
      datadogLogs.logger.setLevel(StatusType[key]);
    }
  }

  private addCustomParametersToContext(context?: Context | undefined) {
    if (!context) {
      context = { globalUserId: this.getGlobalUserId() };
    } else {
      // eslint-disable-next-line
      context['globalUserId'] = this.getGlobalUserId();
    }
    return context;
  }

  private getGlobalUserId() {
    try {
      const token = this.cookieService.getCookieByName(environment.cookieNames.accessToken);
      const decoded: any = jwt_decode(token);
      if ('sub' in decoded) {
        return decoded.sub;
      }
    } catch {
      return -1;
    }
  }
}
