
<app-confirmation-modal
    [headline]="'Anzeige deaktivieren'"
    [confirmButtonText]="'Deaktivieren'"
    [alertText]="deactivationError"
    [buttonsDisabled]="deactivationInProgress"
    (closeModal)="closeModalEvent.emit()"
    (cancel)="closeModalEvent.emit()"
    (confirm)="onClickDeactivateObject()">

    <p  class="text-100 mb-100"
        data-cy="deactivate-question">
        Möchtest du deine Anzeige wirklich deaktivieren?
    </p>

</app-confirmation-modal>
