<div class="card card--is-first">
  <ng-template #linkContent let-link>
    <div class="cell__row cell-size-200 cell--clickable" tabindex="0">
        <div class="cell__col">
            <i class="sd-icon">{{ link.icon }}</i>
        </div>
        <div class="cell__col has-max-width">
            {{ link.text }}
        </div>
        <div class="cell__col">
            <i class="sd-icon">angle_right</i>
        </div>
    </div>
  </ng-template>

  <ng-template #linkTemplate let-link>
    <a [href]="link.url | stageUrl" [id]="link.id">
      <ng-container *ngTemplateOutlet="linkContent; context: {$implicit: link}"></ng-container>
    </a>
  </ng-template>

  <ng-template #clickTemplate let-link>
    <a (click)="link.url()" [id]="link.id" [attr.data-cy]="link.dataCy">
      <ng-container *ngTemplateOutlet="linkContent; context: {$implicit: link}"></ng-container>
    </a>
  </ng-template>

  <div *ngFor="let link of links; last as isLast" [ngClass]="isLast ? 'cell' : 'cell has-divider--inset'">
    <ng-container *ngTemplateOutlet="(link.url | isString) ? linkTemplate : clickTemplate; context: {$implicit: link}"></ng-container>
  </div>
</div>

<app-modal-popup [displayMode]="isMobile ? 'modal' : ''" #popupModalMobile>
  <app-price-table></app-price-table>
</app-modal-popup>
