import { Component, Input, OnInit } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { EstateStatisticService } from '@core/services/estate-statistic.service';
import { TealiumService } from '@core/services/tealium.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-info-card',
  templateUrl: './info-card.component.html',
  styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent implements OnInit {
  @Input() item: UserObject;
  @Input() averageOfObjectCalls: number;

  inboxLink = `https://objektanfragen.immowelt.de/${environment.objektanfragenUrlPart}/objektanfragen/`;
  statisticLink = 'https://www.immowelt.de/objektstatistik?GlobalObjectId=';

  constructor(private readonly estateStatisticService: EstateStatisticService, private readonly tealiumService: TealiumService) {}

  ngOnInit(): void {
    this.inboxLink = this.inboxLink + this.item.globalObjectKey;
    this.statisticLink = this.statisticLink + this.item.globalObjectKey;

    if (this.item.averageOfObjectCalls === undefined || this.item.averageOfObjectCalls === -1) {
      this.estateStatisticService.getStatistic(this.item.globalObjectKey).subscribe(
        (averageOfObjectCalls) => {
          this.item.averageOfObjectCalls = averageOfObjectCalls;
        },
        (error) => {
          this.item.averageOfObjectCalls = -1;
          throw error;
        }
      );
    }
  }

  tealiumLink(event: string) {
    let label = '';
    switch (event) {
      case 'contact-inbox':
        label = 'posteingang';
        break;
      case 'statistic':
      default:
        label = 'statistik deines objekts';
        break;
    }
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: label
    });
  }
}
