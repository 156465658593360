import { Injectable, Inject } from '@angular/core';
import { PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class MobileService {
  isBrowser: boolean;

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  checkIsMobile() {
    if (this.isBrowser && (window as any).mobilecheck) {
      return (window as any).mobilecheck();
    }

    return false;
  }
}
