export enum ModalType{
    Delete,
    Cancel,
    Deactivate,
    DeativateSharedAppartment,
    Activate,
    ActivateSharedApartment,
    Edit,
    None
}
