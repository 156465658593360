import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { format, parseISO } from 'date-fns';

import { ModalContainerService } from '../../../modal-container.service';
import { ModalType } from '../../../modal-type.enum';

@Component({
  selector: 'app-cancel-subscription',
  templateUrl: './cancel-subscription.component.html',
  styleUrls: ['./cancel-subscription.component.scss']
})
export class CancelSubscriptionComponent implements OnChanges {
  @Input()
  public userObject: UserObject;

  public subscriptionText: string | undefined;
  public runtimeUntilReadable: string | undefined;

  constructor(private readonly modalContainerService: ModalContainerService) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.userObject) {
      this.subscriptionText = this.getCancelSubscriptionText(changes.userObject.currentValue);
      this.runtimeUntilReadable = this.getRuntimeUntil(changes.userObject.currentValue);
    }
  }

  public cancelSubscription(): void {
    this.modalContainerService.setModalType(ModalType.Cancel);
  }

  private getCancelSubscriptionText(userObject: UserObject): string | undefined {
    return userObject.subscription ? 'Vertrag beenden' : undefined;
  }

  private getRuntimeUntil(userObject: UserObject): string | undefined {
    return userObject.runtimeUntil ? format(parseISO(userObject.runtimeUntil), 'dd.MM.yyyy') : undefined;
  }
}
