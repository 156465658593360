import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { DistributionTypeEnum } from '../models/enums/distribution-type.enum';

@Injectable({ providedIn: 'root' })
export class DistributionTypeService {
  public isInitialized = false;
  private subject = new Subject<DistributionTypeEnum>();
  private distributionType: DistributionTypeEnum;
  private readonly defaultDistributionType = DistributionTypeEnum.rent;

  private readonly parameterValueRent = 'vermieten';
  private readonly parameterValueSale = 'verkaufen';

  constructor(@Inject(DOCUMENT) private document: Document) {
    this.distributionType = this.defaultDistributionType;
  }

  public initialize(): DistributionTypeEnum {
    if (!this.isInitialized) {
      this.setByQueryString('vertriebsart');
      this.setByQueryString('tab');
      this.setByPathname();
      this.subject.next(this.distributionType);
      this.isInitialized = true;
    }
    return this.distributionType;
  }

  public set(distributionType: DistributionTypeEnum) {
    this.distributionType = distributionType;
    this.subject.next(this.distributionType);
  }

  public getAsObservable(): Observable<DistributionTypeEnum> {
    return this.subject.asObservable();
  }

  public get(): string {
    return this.distributionType;
  }

  private setByQueryString(queryParam: string) {
    let distributionTypeFromQueryString = this.getQueryVariable(queryParam, window.location.href);
    if (distributionTypeFromQueryString) {
      distributionTypeFromQueryString = distributionTypeFromQueryString.toLowerCase();
    }

    if (distributionTypeFromQueryString === this.parameterValueRent) {
      this.distributionType = DistributionTypeEnum.rent;
    } else if (distributionTypeFromQueryString === this.parameterValueSale) {
      this.distributionType = DistributionTypeEnum.buy;
    }
  }

  private setByPathname() {
    const pathname = this.document.location.pathname;
    if (pathname.indexOf('/verkaufen') !== -1) {
      this.distributionType = DistributionTypeEnum.buy;
    } else if (pathname.indexOf('/vermieten') !== -1) {
      this.distributionType = DistributionTypeEnum.rent;
    }
  }

  private getQueryVariable(variable: string, query: string): string | undefined {
    const vars = query.split('?').join(',').split('&').join(',').split(',');
    for (const unsplitPair of vars) {
      const pair = unsplitPair.split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return undefined;
  }
}
