<div id="last-item"
     class="cell__row cell-size-300 cell--clickable"
     data-cy="delete-options"
     tabindex="0"
     (click)="showDeletionModal()">

     <div class="cell__col has-max-width">
        <p  class="has-font-100"
            data-cy="delete-object-headline">
            Anzeige löschen
        </p>
    </div>
    <div class="cell__col">
        <i class="sd-icon">
                angle_right
        </i>
    </div>
</div>
