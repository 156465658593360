import { Component, Input, OnInit } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { format, parseISO } from 'date-fns';

@Component({
  selector: 'app-cancel-text',
  templateUrl: './cancel-text.component.html',
  styleUrls: ['./cancel-text.component.scss']
})
export class CancelTextComponent implements OnInit {
  @Input() item: UserObject;
  runtimeUntilReadable: string;

  constructor() {}

  ngOnInit(): void {
    if (this.item.runtimeUntil) {
      const runtimeUntil = parseISO(this.item.runtimeUntil);
      this.runtimeUntilReadable = format(runtimeUntil, 'dd.MM.yyyy');
    }
  }

  getSwitchExpression() {
    if (this.runtimeUntilReadable && this.item.subscription && this.item.visible) {
      return 'ACTIVE_WITH_SUBSCRIPTION_IN_FUTURE';
    } else if (this.runtimeUntilReadable && this.item.subscription && this.item.isRuntimeInFuture && !this.item.visible) {
      return 'INACTIVE_WITH_SUBSCRIPTION_IN_FUTURE';
    } else if (this.runtimeUntilReadable && !this.item.subscription && this.item.isRuntimeInFuture) {
      return 'INACTIVE_WITH_NO_SUBSCRIPTION_IN_FUTURE';
    } else if (this.runtimeUntilReadable && !this.item.subscription && !this.item.isRuntimeInFuture) {
      return 'INACTIVE_WITH_NO_SUBSCRIPTION_IN_PAST';
    } else if (this.item.visible && !this.item.subscription && !this.item.isRuntimeInFuture) {
      return 'ACTIVE_WITH_NO_SUBSCRIPTION_IN_PAST';
    } else {
      return 'NEVER_ACTIVE';
    }
  }
}
