import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertText } from '@core/models/enums/alert-key.enum';
import { UserObject } from '@core/models/user-object.interface';
import { ObjectProviderService } from '@core/services/object-provider.service';
import { ObjectService } from '@core/services/object.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-activate-shared-apartment-modal',
  templateUrl: './activate-shared-apartment-modal.component.html',
  styleUrls: ['./activate-shared-apartment-modal.component.scss']
})
export class ActivateSharedApartmentModalComponent {
  public alertText: AlertText | undefined;
  public activationInProgress = false;

  @Input()
  public userObject: UserObject;

  @Output()
  public readonly closeModalEvent = new EventEmitter<void>();

  constructor(
    private readonly objectService: ObjectService,
    private readonly objectProviderService: ObjectProviderService
  ) { }

  public onClickActivateObject(): void{
    this.activationInProgress = true;

    this.objectService.activateObject(this.userObject.globalObjectKey).pipe(
      first()
    ).subscribe(result  => {
      this.activationInProgress = false;
      if (result) {
        this.alertText = undefined;

        this.userObject.activationRequest = true;
        this.objectProviderService.startStatePolling(this.userObject);
        this.closeModalEvent.emit();
      } else {
        this.alertText = AlertText.ActivationError;
      }
    });
  }

}
