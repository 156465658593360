import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthService } from '@core/services/auth.service';
import { CookieService } from '@core/services/cookie.service';
import { NavigationUiService } from '@core/services/navigation-ui.service';
import { NavigationUiTypeEnum } from '@core/models/enums/navigation-ui-type.enum';

@Component({
  selector: 'app-automated-test',
  templateUrl: './automated-test.component.html'
})
export class AutomatedTestComponent implements OnInit {
  public automatedTestCreateModelForm: FormGroup;

  constructor(
    private cookieService: CookieService,
    private formBuilder: FormBuilder,
    private readonly authService: AuthService,
    private router: Router,
    private readonly navigationUI: NavigationUiService,
  ) {}

  ngOnInit() {
    this.navigationUI.setNavigationType(NavigationUiTypeEnum.full);

    const viewmodel = this.getSessionCreateModelFromQuery();

    this.automatedTestCreateModelForm = this.formBuilder.group({
      username: [viewmodel.username, Validators.required],
      password: [viewmodel.password, Validators.required]
    });

    if (this.automatedTestCreateModelForm.get('username')?.value && this.automatedTestCreateModelForm.get('password')?.value) {
      this.submit();
    }
  }

  submit(): void {
    this.authService
      .getTokens(this.automatedTestCreateModelForm.get('username')?.value, this.automatedTestCreateModelForm.get('password')?.value)
      .subscribe((result: any) => {
        this.cookieService.createSessionCookie(environment.cookieNames.accessToken, result.access_token);
        this.cookieService.createSessionCookie(environment.cookieNames.refreshToken, result.refresh_token);
        this.router.navigate(['']);
      });
  }

  getSessionCreateModelFromQuery() {
    const username = this.getQueryVariable('username', window.location.href);
    const password = this.getQueryVariable('password', window.location.href);
    return { username, password };
  }

  getQueryVariable(variable: string, query: string): string | undefined {
    const vars = query.split('?').join(',').split('&').join(',').split(',');
    for (const unsplitPair of vars) {
      const pair = unsplitPair.split('=');
      if (decodeURIComponent(pair[0]) === variable) {
        return decodeURIComponent(pair[1]);
      }
    }
    return undefined;
  }
}
