import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { environment } from '@environments/environment';

@Injectable()
export class AutomatedTestGuard implements CanActivate {
  constructor(private router: Router) {}

  canActivate() {
    if (environment.stage === 'live') {
      return this.router.parseUrl('/');
    } else {
      return true;
    }
  }
}
