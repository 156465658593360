import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ContactRequestInboxItem } from '../models/contact-request-inbox-item.interface';

@Injectable({ providedIn: 'root' })
export class ContactRequestInboxService {
  constructor(private readonly httpClient: HttpClient) {}

  public getCount(globalObjectKeyArray: string[]) {
    const globalObjectKeys = globalObjectKeyArray.join(',');

    return this.httpClient.get<ContactRequestInboxItem[]>(
      environment.publicGatewayUrl + 'contactrequestinbox/public/v1/objectcontactinbox/counts?globalObjectIds=' + globalObjectKeys
    );
  }
}
