import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SignInUiPathnameService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  public getPath() {
    const currentPathname = this.document.location.pathname;

    let signInUiPath = '/eigentuemerwelt/home/vermieten';

    if (currentPathname.includes('verkaufen')) {
      signInUiPath = '/eigentuemerwelt/home/verkaufen';
    }

    return encodeURIComponent(signInUiPath);
  }
}
