<div class="background">
    <div class="container wrapper">
        <div class="row">
            <div class="col col-500-12">
                <app-alertbox-ie></app-alertbox-ie>
            </div>
        </div>
        <div class="row">
            <div class="col offset-400-0 offset-500-2 col-400-12 col-500-8">
                <app-object-list-header></app-object-list-header>
                <app-object-list></app-object-list>
                <app-object-list-footer></app-object-list-footer>

                <app-cancel-modal></app-cancel-modal>
                <app-modal-container *ngIf="modalContainerService.isModalVisible$ | async"></app-modal-container>
            </div>
        </div>
    </div>
</div>

<app-page-footer></app-page-footer>
