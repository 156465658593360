import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-modal-popup',
  templateUrl: './modal-popup.component.html',
  styleUrls: ['./modal-popup.component.scss']
})
export class ModalPopupComponent implements OnInit {
  @ViewChild('modal') modal: ElementRef;
  @Input() displayMode: string;
  @Input() flexEnd: string;
  defaultClass = 'modal modal--200';
  currentClass = '';
  currentStyle = '';
  activeClass = 'modal--is-shown';

  constructor() { }

  ngOnInit(): void {
    this.currentClass = this.defaultClass;
  }

  openModal() {
    this.currentClass = this.defaultClass + ' ' + this.displayMode + ' ' + this.flexEnd;
    window.document.body.style.overflow = 'hidden';

    setTimeout(() => {
      this.modal.nativeElement.classList.add(this.activeClass);
    }, 250);
  }

  closeModal() {
    this.modal.nativeElement.classList.remove(this.activeClass);

    setTimeout(() => {
      this.currentClass = this.defaultClass;
      this.currentStyle = '';
      window.document.body.style.overflow = '';
    }, 250);
  }
}
