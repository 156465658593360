import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavigationUiTypeEnum } from '@core/models/enums/navigation-ui-type.enum';
import { ObjectPreviewErrorTypeEnum } from '@core/models/enums/object-preview-error-type.enum';
import { NavigationUiService } from '@core/services/navigation-ui.service';
import { SignInUiPathnameService } from '@core/services/sign-in-ui-pathname.service';
import { environment } from '@environments/environment';
import { UrlService } from '@core/services/url.service';
import { PreviewAlertService } from './components/preview-alert/preview-alert.service';

@Component({
  selector: 'app-object-preview',
  templateUrl: './object-preview.component.html',
  styleUrls: ['./object-preview.component.scss']
})
export class ObjectPreviewComponent implements OnInit {
  public globalObjectKey: string;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly previewAlert: PreviewAlertService,
    private readonly navigationUI: NavigationUiService,
    private readonly router: Router,
    private readonly signInUiPathnameService: SignInUiPathnameService,
    private readonly urlService: UrlService
  ) {}

  public ngOnInit(): void {
    this.getGlobalObjectKey();
  }

  private getGlobalObjectKey(): void {
    this.route.params.subscribe(async param => {
      this.globalObjectKey = await param.gok;
    });
  }

  public onLoad(): void {
    this.navigationUI.setNavigationType(NavigationUiTypeEnum.minimal);
    this.previewAlert.setVisibility(true);
  }

  public onError(error: any): void {
    this.previewAlert.setVisibility(false);
    const previewError = error.detail;
    const signUiPath = this.signInUiPathnameService.getPath();

    switch (previewError.code.toLowerCase()) {
      case ObjectPreviewErrorTypeEnum.Forbidden:
        this.router.navigate([`error/${ObjectPreviewErrorTypeEnum.Forbidden}`]).then();
        break;
      case ObjectPreviewErrorTypeEnum.NotFound:
        this.router.navigate([`error/${ObjectPreviewErrorTypeEnum.NotFound}`]).then();
        break;
      case ObjectPreviewErrorTypeEnum.Unknown:
        this.router.navigate([`error/${ObjectPreviewErrorTypeEnum.Unknown}`]).then();
        break;
      case ObjectPreviewErrorTypeEnum.NotAuthenticated:
        this.urlService.navigateToExternalUrl(environment.signInUrl + environment.signInTargetUrl + signUiPath);
        break;
      default:
        this.router.navigate([`error/${ObjectPreviewErrorTypeEnum.Unknown}`]).then();
        break;
    }
  }
}
