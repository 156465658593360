import { Component, OnInit } from '@angular/core';
import { TealiumService } from '@core/services/tealium.service';

@Component({
  selector: 'app-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss']
})
export class EmptyListComponent implements OnInit {
  public readonly url = 'https://www.immowelt.de/immobilien/erfassung/erfassung.aspx';

  constructor(private readonly tealiumService: TealiumService) {
  }

  ngOnInit(): void {}

  tealiumLink() {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'anzeige aufgeben'
    });
  }
}
