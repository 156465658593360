<section class="slider-wrapper" [ngStyle]="sliderWrapperStyle">
    <ul class="slider-inner" #slider>
        <li id="slide-{{i + 1}}" *ngFor="let item of items; let i = index" class="slider-item">
            <ng-container [ngTemplateOutlet]="item.tpl"></ng-container>
        </li>
    </ul>
</section>
<div *ngIf="showControls">
    <div class="slider-pagination">
        <button id="slider-prev" (click)="prev()" class="icon-btn icon-btn--50"><span class="sd-icon">angle_left</span></button>
        <div class="slider-pagination__dots">
            <div class="slider-pagination__dot" [ngClass]="{'is-active' : currentSlide === i}" *ngFor="let item of dotItems; let i = index"></div>
        </div>
        <button id="slider-next" (click)="next()" class="icon-btn icon-btn--50"><span class="sd-icon">angle_right</span></button>
    </div>
</div>