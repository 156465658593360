import { Component, Input } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { TealiumService } from '@core/services/tealium.service';
import { ModalContainerService } from '../../../modal-container/modal-container.service';


@Component({
  selector: 'app-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss']
})
export class EditButtonComponent {
  @Input()
  public userObject: UserObject;
  constructor(
    private readonly modalContainerService: ModalContainerService,
    private readonly tealiumService: TealiumService,
  ) {}

  onModalOpen() {
    this.modalContainerService.openEditModal(this.userObject);
    this.tealiumLink();
  }

  tealiumLink() {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'anzeige bearbeiten'
    });
  }
}
