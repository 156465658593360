import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { ProductSelectionCreateSession, ProductSelectionMappedSession } from '../models/product-selection-session.interface';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class ProductSelectionService {
  constructor(private readonly httpClient: HttpClient, private readonly logger: LoggingService) {}

  public createSession(globalObjectKey: string) {
    return this.httpClient
      .post<ProductSelectionCreateSession>(environment.publicGatewayUrl + 'productselection/v1/Session', {
        globalObjectKey,
        backButtonUrl: environment.productSelection.backButtonUrl,
        userType: 0,
        mediaId: 0
      })
      .pipe(
        map((value) => {
          if (value.error && value.error.errors && value.error.errors.length > 0) {
            this.logger.error('Error from product selection for global object key ' + globalObjectKey, value);
            throw new Error('Error from product selection for global object key ' + globalObjectKey);
          }

          return { redirectUrl: value.redirectUrl } as ProductSelectionMappedSession;
        })
      );
  }
}
