import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NavigationUiTypeEnum } from '@core/models/enums/navigation-ui-type.enum';
import { NavigationUiService } from '@core/services/navigation-ui.service';
import { UrlService } from '@core/services/url.service';
import { ErrorPageMessageInterface } from '@shared/components/error-page/interfaces/error-page-message.interface';
import { ErrorPageMessagesService } from '@shared/components/error-page/services/error-page-messages.service';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
})
export class ErrorPageComponent implements OnInit {
  public errorMessage: ErrorPageMessageInterface | any;

  constructor(
    private readonly navigationUI: NavigationUiService,
    private readonly route: ActivatedRoute,
    private readonly errorPageMessageService: ErrorPageMessagesService,
    private readonly urlService: UrlService
  ) {}

  public ngOnInit(): void {
    this.navigationUI.setNavigationType(NavigationUiTypeEnum.minimal);

    this.route.params.pipe(take(1))
      .subscribe((error: { type: string } | any) => {
        this.errorMessage = this.errorPageMessageService.getErrorMessage(error.type);
      });
  }

  public forwardTo(link: string): void {
    this.urlService.navigateToExternalUrl(link);
  }
}
