import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { PreventBackNavigationService } from '../services/prevent-back-navigation.service';

@Injectable({
  providedIn: 'root'
})
export class PreventBackNavigationGuard implements CanDeactivate<unknown> {
  constructor(private readonly preventBackNavigationService: PreventBackNavigationService) {}
  canDeactivate(): boolean {
    if (this.preventBackNavigationService.preventBack) {
      this.preventBackNavigationService.preventBack = false;
      return false;
    }
    return true;
  }
}
