import { Component, OnInit } from '@angular/core';
import { UserService } from '@core/services/user.service';

@Component({
  selector: 'app-salutation',
  templateUrl: './salutation.component.html',
  styleUrls: ['./salutation.component.scss']
})
export class SalutationComponent implements OnInit {
  firstName: string;

  constructor(private readonly userService: UserService) {}

  ngOnInit(): void {
    this.userService.get().subscribe((result) => {
      this.firstName = result.firstName;
    });
  }
}
