import { Component, OnDestroy, OnInit } from '@angular/core';
import { PreviewAlertService } from './preview-alert.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-preview-alert',
  templateUrl: './preview-alert.component.html',
  styleUrls: ['./preview-alert.component.scss'],
})
export class PreviewAlertComponent implements OnInit, OnDestroy {

  public isVisible: boolean;
  public readonly showCloseTabButton = Boolean(window.opener);
  private readonly subscription: Subscription = new Subscription();

  constructor(private readonly previewAlert: PreviewAlertService) { }

  public ngOnInit(): void {
    this.subscription.add(this.previewAlert.isVisible.subscribe(visible => this.isVisible = visible));
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public closeWindow(): void {
    window.close();
    this.previewAlert.setVisibility(false);
  }

}
