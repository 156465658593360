<div *ngIf="subscriptionText && runtimeUntilReadable"
      class="cell__row cell-size-300 cell--clickable"
      data-cy="activate-ad"
      tabindex="0"
      (click)="cancelSubscription()">
    <div class="cell__col has-max-width">
        <p  class="has-font-100"
            data-cy="activate-caption-text">
            {{ subscriptionText }}
        </p>
        <p  class="has-font-75 color-grey-500"
            data-cy="change-content-subtext">
            <span data-cy="automatic-extension-text">Automatische Verlängerung der Anzeige am {{runtimeUntilReadable}} um deine anfangs gewählte Laufzeit.</span>
        </p>
    </div>
    <div class="cell__col">
        <i class="sd-icon">
            angle_right
        </i>
    </div>
</div>
