import { Component } from '@angular/core';

import { ModalContainerService } from '../../../modal-container.service';
import { ModalType } from './../../../modal-type.enum';

@Component({
  selector: 'app-delete-item',
  templateUrl: './delete-item.component.html',
  styleUrls: ['./delete-item.component.scss']
})
export class DeleteItemComponent {
  constructor(private readonly modalContainerService: ModalContainerService) {}

  public showDeletionModal(): void {
    this.modalContainerService.setModalType(ModalType.Delete);
  }
}
