import { Injectable } from '@angular/core';
import { timer } from 'rxjs';
import { distinctUntilChanged, filter, first, map, switchMap, take } from 'rxjs/operators';
import { UserObject } from '../models/user-object.interface';
import { RealEstateObjectStateEnum } from './../models/enums/real-estate-object-state.enum';
import { ObjectService } from './object.service';

@Injectable()
export class ObjectProviderService {

  constructor(private readonly objectService: ObjectService) {}

  public startStatePolling(item: UserObject): void {
    const initialWait = 2000; // ms
    const pollingInterval = 5000; //ms
    const maximumRetries = 10;

    // Start polling for state changes after 2 seconds and repeat every 5 seconds
    timer(initialWait, pollingInterval)
      .pipe(
        switchMap(() => this.objectService.getState(item.globalObjectKey).pipe(map((result) => result.state))),
        take(maximumRetries), // Only try it 10 times
        distinctUntilChanged(),
        filter((x) => x === RealEstateObjectStateEnum.visible),
        first(), // After we get a state change, stopp polling
      )
      .subscribe((newState) => this.setState(item, newState));
  }

  private setState(item: UserObject, newState: RealEstateObjectStateEnum): void {
    item.activationRequest = newState === RealEstateObjectStateEnum.activationPending;
    item.visible = newState === RealEstateObjectStateEnum.visible;
  }
}
