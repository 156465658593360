import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'objectStatusText'
})
export class ObjectStatusTextPipe implements PipeTransform {
  transform(activationRequest: boolean, visible: boolean): string {
    if (activationRequest) {
      return 'In Prüfung';
    } else if (visible) {
      return 'Anzeige aktiv';
    } else {
      return 'Anzeige inaktiv';
    }
  }
}
