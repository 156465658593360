import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { ObjectService } from '@core/services/object.service';
import { finalize } from 'rxjs/operators';
import { AlertText } from '../../../../../core/models/enums/alert-key.enum';

@Component({
  selector: 'app-deactivation-modal',
  templateUrl: './deactivation-modal.component.html',
  styleUrls: ['./deactivation-modal.component.scss']
})
export class DeactivationModalComponent {
  public deactivationInProgress = false;
  public deactivationError: AlertText | undefined = undefined;

  @Input()
  public userObject: UserObject;

  @Output()
  public readonly closeModalEvent = new EventEmitter<any>();

  constructor(private readonly objectService: ObjectService) {}

  public onClickDeactivateObject(): void {
    if (this.deactivationInProgress) {
      return;
    }

    this.deactivationInProgress = true;

    this.objectService
      .deactivateObject(this.userObject.globalObjectKey)
      .pipe(
        finalize(() => {
          this.deactivationInProgress = false;
        })
      )
      .subscribe((success) => {
        this.deactivationError = success ? undefined : AlertText.DeactivationError;
        if (success) {
          this.userObject.visible = false;
          this.deactivationInProgress = false;
          this.closeModalEvent.emit();
        }
      });
  }
}
