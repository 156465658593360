import { Location, PopStateEvent } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationCancel, Router } from '@angular/router';
import { DistributionTypeEnum } from '@core/models/enums/distribution-type.enum';
import { NavigationUiTypeEnum } from '@core/models/enums/navigation-ui-type.enum';
import { DatadogService } from '@core/services/datadog.service';
import { DistributionTypeService } from '@core/services/distribution-type.service';
import { IeService } from '@core/services/ie.service';
import { JwtService } from '@core/services/jwt.service';
import { NavigationUiService } from '@core/services/navigation-ui.service';
import { PreventBackNavigationService } from '@core/services/prevent-back-navigation.service';
import { TealiumService } from '@core/services/tealium.service';
import { UrlService } from '@core/services/url.service';
import { UserService } from '@core/services/user.service';
import { Subscription } from 'rxjs';
import { filter, switchMap } from 'rxjs/operators';

import { ModalContainerService } from './components/modal-container/modal-container.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit, OnDestroy {
  private readonly subscriptions: Subscription = new Subscription();
  public isIe: boolean;

  constructor(
    private readonly title: Title,
    private readonly tealiumService: TealiumService,
    private readonly jwtService: JwtService,
    private readonly datadogService: DatadogService,
    private readonly userService: UserService,
    private readonly urlService: UrlService,
    private readonly ieService: IeService,
    private readonly distributionTypeService: DistributionTypeService,
    private readonly location: Location,
    private readonly preventBackNavigationService: PreventBackNavigationService,
    private readonly router: Router,
    private readonly navigationUI: NavigationUiService,
    public readonly modalContainerService: ModalContainerService
  ) {}

  public ngOnInit(): void {
    this.navigationUI.setNavigationType(NavigationUiTypeEnum.full);
    this.isIe = this.ieService.isIe();

    this.datadogService.logInfo('Application started');

    this.title.setTitle('Meine Immobilien');
    this.tealiumService.setSpaTealiumConfig();
    this.tealiumService.loadTealiumScript({ triggerInitialView: false });

    this.initSubscriptions();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initSubscriptions(): void {
    this.initJwtServiceSubscription();
    this.initHistoryHandlingSubscription();
  }

  private initJwtServiceSubscription(): void {
    this.subscriptions.add(
      this.jwtService
        .getAccessToken(true)
        .pipe(switchMap(() => this.userService.loadUserFromService()))
        .subscribe((value) => {
          if (value.type !== 'PRIVATE') {
            this.datadogService.logError('Invalid customer type for home-of-owners ui!');
            this.urlService.navigateToExternalUrlOnStage('https://www.immowelt.de/myiw/meineimmowelt/myiwanbieten.aspx');
          }
        })
    );
  }

  private initHistoryHandlingSubscription(): void {
    this.initLocationSubscription();
    this.initRoutingSubscription();
  }

  private initLocationSubscription(): void {
    this.subscriptions.add(
      this.location.subscribe((popState: PopStateEvent): void => {
        this.initDistributionType(popState);
      })
    );
  }

  private initDistributionType(popState: PopStateEvent): void {
    const rentUrl = '/vermieten';
    const buyUrl = '/verkaufen';

    // Prevent the normal browser back button handling
    this.preventBackNavigationService.preventBack = true;

    if (popState.url === rentUrl) {
      this.distributionTypeService.set(DistributionTypeEnum.rent);
      return;
    }

    if (popState.url === buyUrl) {
      this.distributionTypeService.set(DistributionTypeEnum.buy);
      return;
    }

    // We are leaving rent/buy, therefore we don't want to prevent the browser back button
    this.preventBackNavigationService.preventBack = false;
  }

  private initRoutingSubscription(): void {
    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationCancel =>
              // We just need NacigationCancel events that occured on routing to verkaufen or vermieten
              event instanceof NavigationCancel && (event.url.includes('/verkaufen') || event.url.includes('/vermieten'))
          )
        )
        .subscribe((event: NavigationCancel) => {
          // The preventBack and canDeactivate functionality created a new history entry without the correct location.
          // So we need to replace this entry wir the correct location.
          this.location.replaceState(event.url);
        })
    );
  }
}
