import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ContentService } from '@core/services/content.service';
import { MobileService } from '@core/services/mobile.service';
import { ResizeService } from '@core/services/resize.service';
import { forkJoin, Subscription } from 'rxjs';

@Component({
  selector: 'app-price-table',
  templateUrl: './price-table.component.html',
  styleUrls: ['./price-table.component.scss']
})
export class PriceTableComponent implements OnInit {
  @Output() isVisible: EventEmitter<boolean> = new EventEmitter();
  public switchModalContent: string;
  public priceSaleContent: string;
  public priceRentContent: string;

  isMobile = false;
  subscription: Subscription;

  private readonly rentId = 'f1a985d6983da3140644437b7295fd7d';
  private readonly saleId = '6b2d19dc1e4dea39a267c4df2a75ef4a';

  constructor(
    private resizeService: ResizeService,
    private mobileService: MobileService,
    private readonly contentService: ContentService
  ) {}

  ngOnInit(): void {
    this.switchModalContent = 'rent';
    this.isMobile = this.mobileService.checkIsMobile();
    this.subscription = this.resizeService.observable.subscribe(() => {
      this.isMobile = this.mobileService.checkIsMobile();
    });

    this.emitVisibility();

    if (!this.priceRentContent && !this.priceSaleContent) {
      forkJoin({
        rent: this.contentService.getContent(this.rentId),
        sale: this.contentService.getContent(this.saleId)
      }).subscribe((result) => {
        this.priceRentContent = result.rent?.body ?? '';
        this.priceSaleContent = result.sale?.body ?? '';
        this.emitVisibility();
      });
    }
  }

  public showRent() {
    this.switchModalContent = 'rent';
  }

  public showSale() {
    this.switchModalContent = 'sale';
  }

  private emitVisibility() {
    setTimeout(() => {
      this.isVisible.emit(this.priceRentContent !== '' && this.priceSaleContent !== '');
    }, 0);
  }
}
