import { Component } from '@angular/core';
import { ModalContainerService } from './modal-container.service';
import { ModalType } from './modal-type.enum';


@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss']
})
export class ModalContainerComponent {

  public readonly ModalType = ModalType;

  constructor(public readonly modalContainerService: ModalContainerService) {
  }


  public closeModal(): void {
    this.modalContainerService.closeModal();
  }

}
