<div *ngIf="isIe" class="alertbox alertbox--standard alertbox--warning alertbox--is-shown">
    <div class="alertbox__wrapper">
        <div class="alertbox__icon">
            <span class="sd-icon">exclamation</span>
        </div>
        <div class="alertbox__text">
            <h3>Hinweis: Dein Browser ist veraltet!</h3>
            <p>Damit Du den vollen Funktionsumfang unserer Seite verwenden kannst, aktualisiere bitte Deinen Browser oder verwende einen alternativen Browser.
                Wir optimieren unsere Webseite für folgende Browser, klicke bitte auf eines dieser Symbole um den Browser herunterzuladen.
            </p>
            <a href="https://www.google.de/chrome/"><img class="logo-chrome" [src]="logoChromeUrl" alt="Chrome"></a>
            <a href="https://www.mozilla.org/de/firefox/"><img class="logo-firefox" [src]="logoFirefoxUrl" alt="Firefox"></a>
        </div>
    </div>
</div>



