import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';

@Injectable({ providedIn: 'root' })
export class LoggingService {
  private isLive: boolean;
  constructor() {
    this.isLive = environment.stage === 'live';
  }

  public info(message?: any, ...optionalParams: any[]): void {
    if (!this.isLive) {
      console.log(message, optionalParams);
    }
  }

  public warn(message?: any, ...optionalParams: any[]): void {
    if (!this.isLive) {
      console.warn(message, optionalParams);
    }
  }

  public error(message?: any, ...optionalParams: any[]): void {
    if (!this.isLive) {
      console.error(message, optionalParams);
    }
  }
}
