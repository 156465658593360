import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UrlService } from '@core/services/url.service';
import { environment } from '@environments/environment';
import { addMinutes, fromUnixTime, isPast } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { CookieService } from './cookie.service';
import { LoggingService } from './logging.service';
import { SignInUiPathnameService } from './sign-in-ui-pathname.service';
import { SignInUiService } from './sign-in-ui.service';

@Injectable({ providedIn: 'root' })
export class JwtService {
  constructor(
    private readonly cookieService: CookieService,
    private readonly signInUiService: SignInUiService,
    private readonly loggingService: LoggingService,
    private readonly signInUiPathnameService: SignInUiPathnameService,
    private readonly urlService: UrlService
  ) {}

  public getAccessToken(refreshToken: boolean) {

    const accessToken = this.cookieService.getCookieByName(environment.cookieNames.accessToken);
    if (!accessToken) {
      this.loggingService.info('No Access token - Redirecting to SignIn-Ui ...');
      this.redirectToSignInUi();
    }

    if (refreshToken) {
      const decoded: any = jwt_decode(accessToken);
      if (isPast(addMinutes(fromUnixTime(decoded.exp), -2))) {
        this.loggingService.info('Access token is expired - Trying to refresh...');
        return this.signInUiService.refreshToken().pipe(
          map(() => {
            this.loggingService.info('Access token was expired - Refreshed via SignIn-Ui ...');
            return { refreshed: true, accessToken: this.cookieService.getCookieByName(environment.cookieNames.accessToken) };
          }),
          catchError((error) => {
            const unauthorizedStatusCode = 401;
            if (error instanceof HttpErrorResponse && error.status === unauthorizedStatusCode) {
              this.loggingService.info('Refresh token is not valid - Redirecting to SignIn-Ui ...');
              this.redirectToSignInUi();
            }
            throw error;
          })
        );
      }
    }

    this.loggingService.info('Access token is valid and not expired');
    return of({ refreshed: false, accessToken });
  }


  private redirectToSignInUi(): void {
    const path = this.signInUiPathnameService.getPath();
    this.urlService.navigateToExternalUrl(environment.signInUrl + environment.signInTargetUrl + path);
  }
}
