<div class="background">
  <sd-container>
    <sd-row>
      <sd-col>
        <form class="py-200" [formGroup]="automatedTestCreateModelForm" id="automatedTestCreate" (ngSubmit)="submit()">
          <sd-form-field sdRequired [sizeX]="300">
            <label sdLabel for="Username">Username</label>
            <input sdInput id="Username" type="text" formControlName="username"/>
          </sd-form-field>
          <sd-form-field sdRequired [sizeX]="300">
            <label sdLabel for="Password">Password</label>
            <input sdInput id="Password" type="password" formControlName="password"/>
          </sd-form-field>
          <sd-button [size]="100" type="submit" class="btn_01 ci_color">Enter Session</sd-button>
        </form>
      </sd-col>
    </sd-row>
  </sd-container>
</div>
