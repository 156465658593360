<div class="card">
    <span class="has-font-200 is-bold" data-cy="helpful-calculators">Hilfreiche Rechner</span>
    <app-slider *ngIf="!isMobile" #mobileSlider (touchstart)="swipe($event, 'start')" (touchend)="swipe($event, 'end')">
        <ng-container *ngFor="let item of currentSliderItems;">
            <ng-container *appSliderItem>
                <a [href]="item.url | stageUrl" (click)="tealiumLink(item.event)">
                    <img [src]="item.imageUrl" />
                </a>
            </ng-container>
        </ng-container>
    </app-slider>
    <div class="calculator-images" *ngIf="isMobile">
        <a [href]="item.url | stageUrl" *ngFor="let item of currentSliderItems;">
            <img [src]="item.imageMobileUrl" />
        </a>
    </div> 
</div>
