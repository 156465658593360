import { Injectable } from '@angular/core';
import { ErrorPageMessageInterface } from '@shared/components/error-page/interfaces/error-page-message.interface';
import { ObjectPreviewErrorTypeEnum } from '@core/models/enums/object-preview-error-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ErrorPageMessagesService {

  public readonly errorMessages: ErrorPageMessageInterface[] = [
    {
      type: ObjectPreviewErrorTypeEnum.Forbidden,
      title: 'Kein Zugriff auf die Vorschau',
      description: 'Bitte haben Sie Verständnis, dass nur die Erstellerin oder der Ersteller die Anzeige ansehen kann.',
      button: {
        text: 'Zurück zur Startseite',
        route: '/'
      }
    },
    {
      type: ObjectPreviewErrorTypeEnum.NotFound,
      title: 'Keine Vorschau verfügbar',
      description: 'Die Anzeige konnte nicht gefunden werden.',
      button: {
        text: 'Zurück zur Startseite',
        route: '/'
      }
    },
    {
      type: ObjectPreviewErrorTypeEnum.Unknown,
      title: 'Ohje, das hat leider nicht geklappt',
      description: 'Bitte versuchen Sie es später erneut oder wenden Sie sich an unseren Support.',
      button: {
        text: 'Zurück zur Startseite',
        route: '/'
      }
    }
  ];

  getErrorMessage(errorType: string) {
    return this.errorMessages.find(error => error.type === errorType);
  }
}
