import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { catchError, map, timeout } from 'rxjs/operators';
import { throwError, TimeoutError } from 'rxjs';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class ContentService {
  constructor(private readonly http: HttpClient, private readonly loggingService: LoggingService) {}

  getContent(id: string) {
    return this.http.get(environment.publicGatewayUrl.replace(/\/$/, '') + `/content/v1/contents/${id}?_format=json`).pipe(
      timeout(3500),
      map((data: any) => {
        const body: string = data.body ?? '';
        return { body };
      }),
      catchError((error) => {
        if (error instanceof TimeoutError) {
          this.loggingService.error('Timeout loading from Content Service');
        }

        return throwError(error);
      })
    );
  }
}
