import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MobileService } from '@core/services/mobile.service';
import { ResizeService } from '@core/services/resize.service';
import { ModalPopupComponent } from '@shared/components/modal-popup/modal-popup.component';
import { Subscription } from 'rxjs';

import { LinkInterface } from './link.interface';

@Component({
  selector: 'app-card-links',
  templateUrl: './card-links.component.html',
  styleUrls: ['./card-links.component.scss']
})
export class CardLinksComponent implements OnInit, OnDestroy {
  @ViewChild('popupModalMobile', { static: false }) modalPopupComponent: ModalPopupComponent;

  public isMobile = false;

  public readonly links: LinkInterface[] = [
    {
      icon: 'service',
      url: 'https://www.immowelt.de/makler/suche',
      text: 'Passenden Makler finden',
      id: 'find-real-estate-agent'
    },
    {
      icon: 'energy_pass',
      url: 'https://www.immowelt.de/anbieten/energieausweis',
      text: 'Energieausweis bestellen',
      id: 'energy-pass'
    },
    {
      icon: 'euro',
      url: () => {
        this.onModalOpen();
      },
      text: 'Anzeigenpreise ansehen',
      id: 'price-table',
      dataCy: 'price-overview-modal'
    },
    {
      icon: 'check',
      url: 'https://www.immowelt.de/myiw/immobilien/spezial/index.aspx',
      text: 'Anzeigen hervorheben',
      id: 'link-on-top-products'
    },
    {
      icon: 'list_items',
      url: 'https://www.immowelt.de/myiw/meineimmowelt/myiwverwaltung.aspx',
      text: 'Benutzerkonto verwalten',
      id: 'link-administration'
    },
    {
      icon: 'file_pdf',
      url: 'https://www.immowelt.de/myiw/meineimmowelt/verwaltung/accountoverview.aspx',
      text: 'Rechnungen',
      id: 'link-invoice'
    },
    {
      icon: 'email',
      url: 'https:///www.immowelt.de/myiw/meineimmowelt/verwaltung/anbieterprofil.aspx?tab=basis',
      text: 'Anbieterdaten',
      id: 'link-contact'
    },
    {
      icon: 'file_offer',
      url: 'https://www.immowelt.de/anbieten/mietvertrag-erstellen/dashboard',
      text: 'Meine Mietverträge',
      id: 'link-rentalagreements'
    }
  ];

  private subscription: Subscription;

  constructor(private readonly resizeService: ResizeService, private readonly mobileService: MobileService) {}

  public ngOnInit() {
    this.isMobile = this.mobileService.checkIsMobile();
    this.subscription = this.resizeService.observable.subscribe(() => {
      this.isMobile = this.mobileService.checkIsMobile();
    });
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onModalOpen() {
    this.modalPopupComponent.openModal();
  }
}
