<div    *ngIf="!isSingleRoomInApartment"
        class="cell__row cell-size-300 cell--clickable"
        tabindex="0"
        data-cy="link-upgrade"
        (click)="jumpToChangeBooking()">
    <div class="cell__col has-max-width">
        <p  class="has-font-100"
            data-cy="change-booking">
            Buchung ändern
        </p>
        <p  class="has-font-75 color-grey-500"
            data-cy="upgrade-listing">
            {{bookingSubtext}}
        </p>
    </div>
    <div class="cell__col">
        <i class="sd-icon">
            angle_right
        </i>
    </div>
</div>
