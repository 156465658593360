import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { UrlService } from '@core/services/url.service';
import { environment } from '@environments/environment';
import jwt_decode from 'jwt-decode';
import { CookieService } from '../services/cookie.service';
import { LoggingService } from '../services/logging.service';
import { SignInUiPathnameService } from '../services/sign-in-ui-pathname.service';

@Injectable()
export class AuthenticationGuard implements CanActivate {
  constructor(
    private readonly cookieService: CookieService,
    private readonly loggingService: LoggingService,
    private readonly signInUiPathnameService: SignInUiPathnameService,
    private readonly urlService: UrlService
  ) {}

  public canActivate(): boolean {
    try {
      const token = this.cookieService.getCookieByName(environment.cookieNames.accessToken);
      const decoded: any = jwt_decode(token);

      if (!decoded.fully_authenticated) {
        this.loggingService.info('Not fully authenticated - Redirecting to SignIn-Ui ...');
        this.redirectToSignIn();
        return false;
      }

      this.loggingService.info('Fully authenticated - Granting access ...');
      return true;
    } catch (err) {
      this.redirectToSignIn();
      return false;
    }
  }

  private redirectToSignIn(): void{
    const path = this.signInUiPathnameService.getPath();
    const signInUrl = environment.signInUrl + environment.signInTargetForceUrl + path;
    this.urlService.navigateToExternalUrl(signInUrl);
  }
}
