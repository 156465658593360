import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class IeService {
  constructor() {}

  public isIe() {
    if (typeof window !== 'undefined' && window) {
      const ua = window.navigator.userAgent;
      return ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident') > -1 || ua.indexOf('Edge/18.17763') > -1 || ua.indexOf('Edge/18.18362') > -1;
    }
    return false;
  }
}
