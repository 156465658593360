import { Component, Input, OnInit } from '@angular/core';
import { AlertType } from '../../../core/models/enums/alert-type.enum';
import { Icon } from '../../../core/models/enums/icon.enum';
import { AlertText } from './../../../core/models/enums/alert-key.enum';

type AlertClass = 'alertbox--error' | 'alertbox--warning';
@Component({
  selector: 'app-alertbox [alertText]',
  templateUrl: './alertbox.component.html',
  styleUrls: ['./alertbox.component.scss']
})
export class AlertboxComponent implements OnInit {
  public alertClass: AlertClass = 'alertbox--error';

  @Input()
  public alertType: AlertType = AlertType.Error;

  @Input()
  public alertIcon: Icon = Icon.ExclamationTriangle;

  @Input()
  public alertText: AlertText;

  public ngOnInit(): void {
    this.alertClass = this.alertType === AlertType.Error ? 'alertbox--error' : 'alertbox--warning';
  }
}
