import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { JwtService } from '../services/jwt.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  constructor(private jwtService: JwtService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (request.url.includes('signin.immowelt.de') || request.url.includes('/api/auth/oauth/token')) {
      return next.handle(request);
    }

    return this.jwtService.getAccessToken(true).pipe(
      switchMap((result) => {
        if (result.accessToken && !request.headers.has('Authorization')) {
          request = this.addToken(request, result.accessToken);
        }
        return next.handle(request);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string) {
    /*eslint-disable */
    return request.clone({ setHeaders: { Authorization: `Bearer ${token}` } });
    /*eslint-enable */
  }
}
