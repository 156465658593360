import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { ProductSelectionService } from '@core/services/product-selection.service';
import { TealiumService } from '@core/services/tealium.service';
import { UrlService } from '@core/services/url.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Component({
  selector: 'app-activate-ad',
  templateUrl: './activate-ad.component.html',
  styleUrls: ['./activate-ad.component.scss']
})
export class ActivateAdComponent implements OnDestroy {

  @Input()
  public item: UserObject;

  @Output()
  public readonly closeModalEvent = new EventEmitter<void>();

  private readonly subscriptions = new Subscription();
  constructor(
    private readonly productSelectionService: ProductSelectionService,
    private readonly tealiumService: TealiumService,
    private readonly urlService: UrlService
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public closeModal(): void {
    this.closeModalEvent.emit();
  }

  public onExtendRuntime(): void {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'laufzeit laufzeit verlaengern_reaktivierung'
    });

    this.subscriptions.add(
      this.productSelectionService
        .createSession(this.item.globalObjectKey)
        .pipe(
          map(result => result.redirectUrl),
          filter(redirectUrl => !!redirectUrl)
        )
        .subscribe(redirectUrl => this.urlService.navigateToExternalUrl(redirectUrl))
    );
  }
}
