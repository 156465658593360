import { Component, Input, OnInit } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';

import { PreviewLinkService } from '../../../../../../object-preview/services/preview-link.service';

@Component({
  selector: 'app-open-preview',
  templateUrl: './open-preview.component.html',
  styleUrls: ['./open-preview.component.scss']
})
export class OpenPreviewComponent implements OnInit {
  @Input()
  public userObject: UserObject;

  public isObjectActive: boolean;

  constructor(private readonly previewLinkService: PreviewLinkService) {}

  public ngOnInit(): void {
    this.isObjectActive = this.userObject.visible;
  }

  public openPreview(): void {
    this.previewLinkService.openPreview(this.userObject);
  }
}
