import { Component, OnInit } from '@angular/core';

export interface QandA {
  id: string;
  icon: string;
  text: string;
  trackinglabel: string;
  link: string;
  linktext: string;
}

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.scss']
})
export class SupportComponent implements OnInit {
  public items: QandA[];

  constructor() { }

  ngOnInit(): void {
    this.items = [
      {
        id: 'phone',
        icon: 'phone',
        text: 'Servicenummer',
        trackinglabel: 'anbieten_servicenummer',
        link: 'tel:+499115202590',
        linktext: '+49 911 520 25-90'
      },
      {
        id: 'email',
        icon: 'email',
        text: 'E-Mail',
        trackinglabel: 'anbieten_email',
        link: 'mailto:eigentuemer@immowelt.de',
        linktext: 'eigentuemer@immowelt.de'
      }
    ];
  }

}
