import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SmartDesignAngularModule } from '@smartdesign/angular';

import { AppRoutingModule } from './../../../../app-routing.module';
import { CoreModule } from './../../../../core/core.module';
import { SharedModule } from './../../../../shared/shared.module';
import { ActivateAdComponent } from './activate-ad/activate-ad.component';
import { ActivateSharedApartmentModalComponent } from './activate-shared-apartment-modal/activate-shared-apartment-modal.component';
import { CancelModalComponent } from './cancel-modal/cancel-modal.component';
import { DeactivateSharedApartmentModalComponent } from './deactivate-shared-apartment-modal/deactivate-shared-apartment-modal.component';
import { DeactivationModalComponent } from './deactivation-modal/deactivation-modal.component';
import { DeleteObjectContentComponent } from './delete-object-content/delete-object-content.component';
import { EditModalModule } from './edit-modal/edit-modal.module';
import { ModalContainerComponent } from './modal-container.component';
import { ConfirmationModalComponent } from './shared/confirmation-modal/confirmation-modal.component';

@NgModule({
    declarations: [
        ActivateAdComponent,
        ActivateSharedApartmentModalComponent,
        DeactivateSharedApartmentModalComponent,
        DeactivationModalComponent,
        DeleteObjectContentComponent,
        ConfirmationModalComponent,
        CancelModalComponent,
        ModalContainerComponent
    ],
    exports:[
        ModalContainerComponent
    ],
    imports: [

        BrowserModule,
        CommonModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        SmartDesignAngularModule,
        CoreModule,
        BrowserAnimationsModule,
        EditModalModule,
        SharedModule
      ],
      providers:[
      ]
})
export class ModalContainerModule {}
