<div *ngIf="!isObjectActive" 
     class="cell__row cell-size-300 cell--clickable" 
     tabindex="0" 
     (click)="openPreview()" 
     data-cy="object-preview">
  <div class="cell__col has-max-width">
    <p class="has-font-100" data-cy="delete-object-headline">Vorschau ansehen</p>
  </div>
  <div class="cell__col">
    <i class="sd-icon">angle_right</i>
  </div>
</div>
