import { Component, EventEmitter, Input, Output } from '@angular/core';
import { finalize, first } from 'rxjs/operators';
import { AlertText } from '../../../../../core/models/enums/alert-key.enum';
import { ObjectService } from '../../../../../core/services/object.service';
import { UserObject } from './../../../../../core/models/user-object.interface';

@Component({
  selector: 'app-deactivate-shared-apartment-modal',
  templateUrl: './deactivate-shared-apartment-modal.component.html',
  styleUrls: ['./deactivate-shared-apartment-modal.component.scss']
})
export class DeactivateSharedApartmentModalComponent{
  public alertText: AlertText | undefined;

  public deactivationInProgress = false;

  @Input()
  public userObject: UserObject;

  @Output()
  public readonly closeModalEvent = new EventEmitter<void>();

  constructor(private readonly objectService: ObjectService) { }


  public onClickDeactivateObject(): void {
    const globalObjectKey = this.userObject.globalObjectKey;

    this.deactivationInProgress = true;

    this.objectService
      .deactivateObject(globalObjectKey)
      .pipe(
        finalize(() => {
          this.deactivationInProgress = false;
        }),
        first()
      )
      .subscribe(success => {
        this.alertText = success ? undefined : AlertText.DeactivationError;
        if (success) {
          this.userObject.visible = false;
          this.deactivationInProgress = false;
          this.closeModalEvent.emit();
        }
      });
  }

}
