import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { addMonths } from 'date-fns';
import { map } from 'rxjs/operators';
import { environment } from '@environments/environment';
import { EstateStatisticItem } from '../models/estate-statistic-item.interface';

@Injectable({ providedIn: 'root' })
export class EstateStatisticService {
  constructor(private readonly httpClient: HttpClient) {}

  public getStatistic(globalObjectKey: string) {
    const currentDate = new Date();
    return this.httpClient
      .post<EstateStatisticItem[]>(
        environment.publicGatewayUrl + 'estatestatistic/estatestatistic/v1/objectstatistic/detailsperobjectsecure',
        {
          fromDate: addMonths(currentDate, -1).toISOString(),
          globalObjectId: globalObjectKey,
          toDate: currentDate.toISOString()
        }
      )
      .pipe(
        map((estateStatisticItems) => {
          if (estateStatisticItems.length === 0) {
            return 0;
          }

          let sumOfObjectCalls = 0;
          for (const estateStatisticItem of estateStatisticItems) {
            sumOfObjectCalls += estateStatisticItem.anzObjCall;
          }

          return sumOfObjectCalls / estateStatisticItems.length;
        })
      );
  }
}
