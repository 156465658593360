import { Component, OnInit } from '@angular/core';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-avatar-group',
  templateUrl: './avatar-group.component.html',
  styleUrls: ['./avatar-group.component.scss']
})
export class AvatarGroupComponent implements OnInit {
  avatarFace1: string;
  avatarFace2: string;
  avatarFace3: string;

  constructor() {}

  ngOnInit(): void {
    this.avatarFace1 = environment.assetsUrl + 'assets/face_1.png';
    this.avatarFace2 = environment.assetsUrl + 'assets/face_2.png';
    this.avatarFace3 = environment.assetsUrl + 'assets/face_3.png';
  }
}
