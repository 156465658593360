import { Component, OnDestroy, OnInit } from '@angular/core';
import { DistributionTypeEnum } from '@core/models/enums/distribution-type.enum';
import { DistributionTypeService } from '@core/services/distribution-type.service';
import { MobileService } from '@core/services/mobile.service';
import { ResizeService } from '@core/services/resize.service';
import { TealiumService } from '@core/services/tealium.service';
import { Subscription } from 'rxjs';

import { CardInterface } from './card.interface';

@Component({
  selector: 'app-card-group',
  templateUrl: './card-group.component.html',
  styleUrls: ['./card-group.component.scss']
})
export class CardGroupComponent implements OnInit, OnDestroy {
  public isMobile: boolean;
  public cards: CardInterface[] = [];
  public halfCards: CardInterface[] = [];

  private readonly cardsRent: CardInterface[] = [
    {
      id: 'new-acquisition',
      icon: 'add',
      celltext: 'Neue Anzeige aufgeben',
      cellsubtext: 'Schon ab 0€ inserieren*',
      url: 'https://www.immowelt.de/anbieten/immobilienanzeigen',
      label: ''
    }
  ];

  private readonly cardsSale: CardInterface[] = [];

  private readonly halfCardsRent: CardInterface[] = [
    {
      id: 'home-investment',
      icon: 'home_investment',
      celltext: 'Was ist deine Immobilie wert?',
      cellsubtext: '',
      url: 'https://www.immowelt.de/anbieten/immobilienbewertung',
      label: 'immobilie verkaufen'
    },
    {
      id: 'services',
      icon: 'check',
      celltext: 'Alle Tools und Services für Anbieter',
      cellsubtext: '',
      url: 'https://www.immowelt.de/anbieten/eigentuemer-services/vermieten',
      label: 'tools und services'
    }
  ];

  private readonly halfCardsSale: CardInterface[] = [
    {
      id: 'new-acquisition',
      icon: 'add',
      celltext: 'Neue Anzeige aufgeben',
      cellsubtext: '',
      url: 'https://www.immowelt.de/anbieten/immobilienanzeigen',
      label: 'anzeige aufgeben'
    },
    {
      id: 'home-investment',
      icon: 'home_investment',
      celltext: 'Was ist deine Immobilie wert?',
      cellsubtext: '',
      url: 'https://www.immowelt.de/anbieten/immobilienbewertung',
      label: 'immobilie verkaufen'
    },
    {
      id: 'price-comparison',
      icon: 'location_map',
      celltext: 'Jetzt regionale Preise vergleichen',
      cellsubtext: '',
      url: 'https://www.immowelt.de/immobilienpreise/deutschland',
      label: 'immobilie bewerten'
    },
    {
      id: 'services',
      icon: 'check',
      celltext: 'Alle Tools und Services für Anbieter',
      cellsubtext: '',
      url: 'https://www.immowelt.de/anbieten/eigentuemer-services/verkaufen',
      label: 'tools und services'
    }
  ];

  private subscription: Subscription;

  constructor(
    private readonly distributionTypeService: DistributionTypeService,
    private readonly resizeService: ResizeService,
    private readonly mobileService: MobileService,
    private readonly tealiumService: TealiumService
  ) {}

  public ngOnInit(): void {
    this.isMobile = this.mobileService.checkIsMobile();
    this.subscription = this.resizeService.observable.subscribe(() => {
      const isMobile = this.mobileService.checkIsMobile();
      if (isMobile) {
        this.isMobile = isMobile;
      } else {
        this.isMobile = window.innerWidth < 767;
      }
    });

    this.distributionTypeService.getAsObservable().subscribe((distributionType) => {
      this.onDistributionTypeChanged(distributionType);
    });

    this.onDistributionTypeChanged(this.distributionTypeService.get());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public tealiumLink(label: string) {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: label
    });
  }

  private onDistributionTypeChanged(distributionType: string) {
    if (distributionType === DistributionTypeEnum.rent) {
      this.cards = this.cardsRent;
      this.halfCards = this.halfCardsRent;
    } else {
      this.cards = this.cardsSale;
      this.halfCards = this.halfCardsSale;
    }
  }
}
