<div [class]="currentClass" (click)="closeModal()" #modal>
    <div class="modal__dialog">
        <div class="modal__container" (click)="$event.stopPropagation()" data-cy="price-modal">
            <div class="modal__title">
                <h2 class="modal__title-text" data-cy="pricetable-headline">Preisübersicht für eine Online-Anzeige</h2>
                <button type="button" class="icon-btn icon-btn--50 modal__close" (click)="closeModal()" data-cy="close-price-overview">
                    <span class="sd-icon">close</span>
                </button>
            </div>
            <div class="modal__content">
                <div class="modal__content-inner">
                    <ng-content></ng-content>
                </div>
            </div>     
        </div>
    </div>
</div>