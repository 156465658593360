import { Pipe, PipeTransform } from '@angular/core';

import { UrlService } from '../services/url.service';

@Pipe({
  name: 'stageUrl'
})
export class StageUrlPipe implements PipeTransform {

  constructor(private readonly urlService: UrlService) {}

  transform(url: string): string {
    return this.urlService.setStageForUrl(url);
  }

}
