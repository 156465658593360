<div class="alertbox alertbox--standard {{alertClass}} alertbox--is-shown">
    <div class="alertbox__wrapper">
        <div class="alertbox__icon">
            <span class="sd-icon">{{alertIcon}}</span>
        </div>
        <div class="alertbox__text">
            <p>{{alertText}}</p>
        </div>
    </div>
</div>
