import { Component, Input } from '@angular/core';
import { TealiumService } from '@core/services/tealium.service';
import { UrlService } from '@core/services/url.service';

@Component({
  selector: 'app-edit-content-item',
  templateUrl: './edit-content-item.component.html',
  styleUrls: ['./edit-content-item.component.scss']
})
export class EditContentItemComponent {

  @Input()
  public globalObjectKey: string;

  constructor(private readonly tealiumService: TealiumService,
    private readonly urlService: UrlService) { }

  public jumpToRec(): void{
    this.tealiumLink();
    const url = 'https://www.immowelt.de/immobilien/erfassung/erfassung.aspx?gok=' + this.globalObjectKey;
    this.urlService.navigateToExternalUrlOnStage(url);
  }

  private tealiumLink(): void {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'anzeige bearbeiten_inhalte aendern'
    });
  }

}
