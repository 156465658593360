import { Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  public observable: Subject<any>;
  private resizeTimeout: any;
  private renderer: Renderer2;

  constructor(private rendererFactory2: RendererFactory2) {
    this.observable = new Subject();
    this.renderer = this.rendererFactory2.createRenderer(null, null);
    this.renderer.listen('window', 'resize', () => {
      if (this.resizeTimeout) {
        clearTimeout(this.resizeTimeout);
      }
      this.resizeTimeout = setTimeout(
        (() => {
          this.observable.next('window:resize');
        }).bind(this),
        100
      );
    });
  }
}
