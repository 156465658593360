import { Component, OnInit } from '@angular/core';
import { DistributionTypeEnum } from '@core/models/enums/distribution-type.enum';
import { DistributionTypeService } from '@core/services/distribution-type.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-templates-documents',
  templateUrl: './templates-documents.component.html',
  styleUrls: ['./templates-documents.component.scss']
})
export class TemplatesDocumentsComponent implements OnInit {
  showMore = false;

  cellsRent: any[] = [
    {
      celltext: 'Mustermietvertrag',
      downloadUrl: environment.assetsUrl + 'assets/pdf/muster_mietvertrag_standard_immowelt.pdf'
    },
    {
      celltext: 'Vermieter-Checkliste',
      downloadUrl: environment.assetsUrl + 'assets/pdf/checkliste_wohnung_haus_vermieten_immowelt.pdf'
    },
    {
      celltext: 'Mieterselbstauskunft',
      downloadUrl: environment.assetsUrl + 'assets/pdf/muster_mieterselbstauskunft_immowelt.pdf'
    },
    {
      celltext: 'Wohnungsübergabeprotokoll',
      downloadUrl: environment.assetsUrl + 'assets/pdf/muster_wohnungsuebergabeprotokoll_immowelt.pdf'
    },
    {
      celltext: 'Wohnungsgeberbestätigung',
      downloadUrl: environment.assetsUrl + 'assets/pdf/muster_wohnungsgeberbestaetigung_immowelt.pdf'
    },
    {
      celltext: 'Versicherungsüberblick',
      downloadUrl: environment.assetsUrl + 'assets/pdf/merkblatt_versicherungen_hauseigentuemer_immowelt.pdf'
    }
  ];

  cellsSale: any[] = [
    {
      celltext: 'Tipps für Verkäufer',
      downloadUrl: environment.assetsUrl + 'assets/pdf/tipps_fuer_verkaeufer_immowelt.pdf'
    },
    {
      celltext: 'Verkäufer-Checklisten',
      downloadUrl: environment.assetsUrl + 'assets/pdf/checkliste_wohnung_haus_verkaufen_immowelt.pdf'
    },
    {
      celltext: 'Hausübergabeprotokoll',
      downloadUrl: environment.assetsUrl + 'assets/pdf/muster_hausuebergabeprotokoll_immowelt.pdf'
    },
    {
      celltext: 'Versicherungsüberblick',
      downloadUrl: environment.assetsUrl + 'assets/pdf/merkblatt_versicherungen_hauseigentuemer_immowelt.pdf'
    }
  ];

  public cells: any[] = [];

  constructor(private readonly distributionTypeService: DistributionTypeService) {}

  ngOnInit(): void {
    this.distributionTypeService.getAsObservable().subscribe((distributionType) => {
      this.onDistributionTypeChanged(distributionType);
    });

    this.onDistributionTypeChanged(this.distributionTypeService.get());
  }

  private onDistributionTypeChanged(distributionType: string) {
    if (distributionType === DistributionTypeEnum.rent) {
      this.cells = this.cellsRent;
    } else {
      this.cells = this.cellsSale;
    }
  }
}
