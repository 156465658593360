import { ChangeDetectionStrategy, Component, Input, OnDestroy } from '@angular/core';
import { AlertText } from '@core/models/enums/alert-key.enum';
import { UserObject } from '@core/models/user-object.interface';
import { ObjectService } from '@core/services/object.service';
import { Subscription } from 'rxjs';

import { ObjectProviderService } from './../../../../../../../core/services/object-provider.service';
import { ModalContainerService } from './../../../modal-container.service';
import { ModalType } from './../../../modal-type.enum';

@Component({
  selector: 'app-activation-item',
  templateUrl: './activation-item.component.html',
  styleUrls: ['./activation-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ActivationItemComponent implements OnDestroy {

  private readonly subscriptions = new Subscription();
  public readonly AlertText = AlertText;
  public objectStatusText: string;
  public objectStatusSubtext: string;
  public deactivateToggle: boolean;
  public activationError: boolean;

  public isObjectActive: boolean;

  public neverActive = false;

  private userObjectValue: UserObject;

  @Input()
  public set userObject(value: UserObject){
    this.objectStatusText = this.getObjectStatusText(value);
    this.objectStatusSubtext = this.getObjectStatusSubText(value.visible);
    this.deactivateToggle = value.activationRequest || !value.isRuntimeInFuture;
    this.isObjectActive = value.visible;
    this.neverActive = this.wasNeverActive(value);
    this.userObjectValue = value;
  }

  public get userObject(): UserObject{
    return this.userObjectValue;
  }

  constructor(private readonly objectService: ObjectService,
              private readonly objectProviderService: ObjectProviderService,
              private readonly modalContainerService: ModalContainerService) {
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private wasNeverActive(userObject: UserObject): boolean {
    return !userObject.visible && !userObject.subscription && !userObject.runtimeUntil;
  }

  private getObjectStatusText(userObject: UserObject): string{
    if (userObject.visible) {
      return this.getStatusTextForApartmentType(userObject.isSingleRoomInApartment);
    }

    return 'Anzeige inaktiv';
  }

  private getStatusTextForApartmentType(isSingleRoomInApartment: boolean): string{
    return isSingleRoomInApartment ? 'Anzeige deaktivieren' : 'Anzeige aktiv';
  }

  private getObjectStatusSubText(isVisible: boolean | undefined): string {
    return isVisible ? 'Immobilie veröffentlicht': 'Immobilie nicht veröffentlicht';
  }

  public onActivateSwitch(): void {
    if (this.userObject?.isSingleRoomInApartment) {
      this.activateSingleRoomApartment();
      return;
    }

    if (!this.deactivateToggle) {
      this.toggle();
    }
  }

  private activateSingleRoomApartment(): void {
    const newActivationState = !this.isObjectActive;
    this.isObjectActive = newActivationState;

    if (newActivationState === false) {
      this.modalContainerService.setModalType(ModalType.DeativateSharedAppartment);
    }
  }

  private toggle(): void {
    const newActivationState = !this.isObjectActive;
    this.isObjectActive = newActivationState;
    this.deactivateToggle = true;

    if (newActivationState === true && this.userObject.isRuntimeInFuture) {
      this.activateItemWithRuntime();
    } else if (newActivationState === false) {
      this.modalContainerService.setModalType(ModalType.Deactivate);
    }
  }

  private activateItemWithRuntime(): void {

    this.subscriptions.add(
      this.objectService.activateObject(this.userObject.globalObjectKey).subscribe(result => {
        if (result) {
          this.activationError = false;
          this.handleSuccessfullObjectActivation();
        } else {
          this.showObjectActivationError();
        }
      })
    );
  }

  public handleSuccessfullObjectActivation(): void {
    this.objectProviderService.startStatePolling(this.userObject);
    this.userObject.activationRequest = true;

    this.modalContainerService.closeModal();
  }



  private showObjectActivationError(): void {
    this.activationError = true;
    this.isObjectActive = false;
  }

}
