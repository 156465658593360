import { Injectable } from '@angular/core';
import { UtagLinkEvent } from 'src/app/shared/interfaces/utag-link-event.interface';
import { environment } from 'src/environments/environment';
import { LoggingService } from './logging.service';
import { ScriptService } from './script.service';

@Injectable({
  providedIn: 'root'
})
export class TealiumService {
  constructor(private readonly scriptService: ScriptService, private readonly loggingService: LoggingService) {}

  public setSpaTealiumConfig() {
    /*eslint-disable */
    (window as any).utag_cfg_ovrd = { noview: true };
    (window as any).utag_data = {
      app_medienid: 0,
      app_server: 'home-of-owners-ui',
      app_time: Date.now(),
      customer_is_domestic: '0',
      customer_profile: 'kein profil',
      customer_type: 'private',
      hotjar_activated: false
    };
    /*eslint-enable */
  }

  public loadTealiumScript(tealiumConfig: { triggerInitialView: boolean }) {
    /*eslint-disable */
    try {
      const node = this.scriptService.addScript(environment.tealiumUrl, 'head');
      if (tealiumConfig?.triggerInitialView) {
        node.onload = () => {
          this.view();
        };
      }
    } catch {
      // don't care
    }
    /*eslint-enable */
  }

  public link(event: UtagLinkEvent) {
    if ((window as any).utag) {
      try {
        this.loggingService.info('utag link', event);
        /*eslint-disable */
        (window as any).utag.link({
          event_category: event.eventCategory,
          event_action: event.eventAction,
          event_label: event.eventLabel,
          event_type: event.eventType
        });
        /*eslint-enable */
      } catch {
        // don't care
      }
    }
  }

  public view() {
    /*eslint-disable */
    const _this = this;
    let retry = 0;
    const pageViewInterval = setInterval(function () {
      try {
        if ((window as any).utag && (window as any).utag.handler && (window as any).utag.handler.iflag === 1) {
          _this.loggingService.info('utag view', (window as any).utag_data);
          (window as any).utag.view((window as any).utag_data);
          clearInterval(pageViewInterval);
        } else {
          retry++;
          _this.loggingService.info('utag view not ready - trial ' + retry);
          if (retry > 10) {
            clearInterval(pageViewInterval);
          }
        }
      } catch (error) {
        _this.loggingService.error('error sending page view', error);
        clearInterval(pageViewInterval);
      }
    }, 1000);
    /*eslint-enable */
  }
}
