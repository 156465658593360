import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, Subject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { User } from '../models/user.interface';

@Injectable({ providedIn: 'root' })
export class UserService {
  private subject = new Subject<User>();

  constructor(private readonly httpClient: HttpClient) {}

  public loadUserFromService() {
    return this.httpClient.get<User>(environment.cloudGatewayUrl + 'owner-dashboard/v1/user').pipe(
      tap((value) => {
        this.subject.next(value);
      })
    );
  }

  public get(): Observable<User> {
    return this.subject.asObservable();
  }
}
