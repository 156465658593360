export const environment = {
  production: false,
  stage: 'dev',
  assetsUrl: 'https://dev-s.immowelt.org/homeofowners-ui/',
  tealiumUrl: '//tags.tiqcdn.com/utag/immoweltgroup/web.immowelt/dev/utag.js',
  signInUrl: 'https://dev-signin.immowelt.de',
  signInTargetUrl: '/?target=eigentuemerwelt&registrationsource=etw_dashboard&path=',
  signInTargetForceUrl: '/?forceLogin=true&registrationsource=etw_dashboard&target=eigentuemerwelt&path=',
  cloudGatewayUrl: 'https://dev-data.immowelt.de/',
  publicGatewayUrl: 'https://dev-api.immowelt.com/',
  gatewayUrl: 'https://legacy-api-gateway-cloud.tarvos-dev.immocloud.io/api/',
  productSelection: {
    backButtonUrl: 'https://dev.immowelt.de/eigentuemerwelt/home/'
  },
  cookieNames: {
    accessToken: 'oauth.access.token',
    refreshToken: 'oauth.refresh.token'
  },
  authentication: {
    basicAuthHeaderValue: 'Basic b3duZXItZGFzaGJvYXJkLXVpLXN5c3RlbXRlc3Q6eXhxWll1ZVVDWWpzTnBTdQ==',
    tokenResourceName: '/auth/oauth/token'
  },
  imageBaseUrl: 'https://ms-dev.immowelt.org/',
  logLevel: 'debug',
  objektanfragenUrlPart: '0-552'
};
