import { Component, OnDestroy, OnInit } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit, OnDestroy {
  public appIsOnline = true;

  private readonly subscriptions: Subscription = new Subscription();

  constructor() {}

  ngOnInit(): void {
    this.handleAppConnectivityChanges();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private handleAppConnectivityChanges() {
    this.subscriptions.add(
      fromEvent(window, 'online').subscribe(() => {
        this.appIsOnline = true;
      })
    );
    this.subscriptions.add(
      fromEvent(window, 'offline').subscribe(() => {
        this.appIsOnline = false;
      })
    );
  }
}
