<app-confirmation-modal
    [headline]="'Anzeige aktivieren'"
    [confirmButtonText]="'Jetzt aktivieren'"
    [alertText]="alertText"
    [buttonsDisabled]="activationInProgress"
    (closeModal)="closeModalEvent.emit()"
    (cancel)="closeModalEvent.emit()"
    (confirm)="onClickActivateObject()">
    Du möchtest deine WG erneut online stellen? Aktiviere einfach deine Anzeige und sie läuft 8 Wochen lang kostenlos.
</app-confirmation-modal>

