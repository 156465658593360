import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UserObject } from './../../../../core/models/user-object.interface';
import { ModalType } from './modal-type.enum';

@Injectable({
  providedIn: 'root'
})
export class ModalContainerService {
  private readonly modalType$$ = new BehaviorSubject<ModalType>(ModalType.None);
  public readonly modalType$ = this.modalType$$.asObservable();

  public readonly isModalVisible$: Observable<boolean> = this.modalType$.pipe(map((type) => type !== ModalType.None));

  private readonly userObject$$ = new ReplaySubject<UserObject>(1);
  public readonly userObject$: Observable<UserObject> = this.userObject$$.asObservable();

  public openEditModal(userObject: UserObject) {
    this.userObject$$.next(userObject);
    this.setModalType(ModalType.Edit);
  }

  public openCancelModal(userObject: UserObject) {
    this.userObject$$.next(userObject);
    this.setModalType(ModalType.Cancel);
  }

  public closeModal(): void {
    this.setModalType(ModalType.None);
  }

  public setModalType(modalType: ModalType): void {
    this.modalType$$.next(modalType);
  }
}
