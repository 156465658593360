<div class="card card--is-first card--is-grouped card--is-grouped-first" *ngFor="let card of cards;">
  <a [href]="card.url | stageUrl" [attr.data-cy-card-url]="card.id" (click)="tealiumLink(card.label)">
    <div class="cell" [ngClass]="{'has-divider--inset' : isMobile}">
      <div class="cell__row cell-size-500 cell--clickable">
        <div class="cell__col">
          <div class="avatar avatar--400 avatar--square">
            <span class="avatar__icon">
              <i class="sd-icon" [attr.data-cy-card-icon]="card.id">{{card.icon}}</i>
            </span>
          </div>
        </div>
        <div class="cell__col has-max-width">
          <p class="has-font-100" [attr.data-cy-card-text]="card.id">{{card.celltext}}</p>
          <div [ngClass]="{'badge badge--text badge--primary badge-advertise': card.cellsubtext === 'Schon ab 0€ inserieren*'}" class="has-font-75 color-grey-500 display-none">{{card.cellsubtext}}</div>
        </div>
        <div class="cell__col display-none--mobile">
          <div [ngClass]="{'badge badge--text badge--primary badge-advertise': card.cellsubtext === 'Schon ab 0€ inserieren*'}" class="has-font-75 color-grey-500">{{card.cellsubtext}}</div>
        </div>
        <div class="cell__col">
          <i class="sd-icon">angle_right</i>
        </div>
      </div>
    </div>
  </a>
</div>

<div class="row">
  <div class="col col-300-12 col-400-6" *ngFor="let halfCard of halfCards; let last = last">
    <div
      [ngClass]="{'card--is-grouped-last' : isMobile && (halfCards.lastIndexOf(halfCard) + 1 === halfCards.length)}"
      class="card card--is-first card--is-grouped">
      <a [href]="halfCard.url | stageUrl" [attr.data-cy-card-url]="halfCard.id" (click)="tealiumLink(halfCard.icon)">
        <div class="cell has-divider--inset" [ngClass]="{'has-divider--inset' : isMobile && !last}">
          <div class="cell__row cell-size-500 cell--clickable">
            <div class="cell__col">
              <div class="avatar avatar--400 avatar--square">
                <span class="avatar__icon">
                  <i class="sd-icon" [attr.data-cy-card-icon]="halfCard.id">{{halfCard.icon}}</i>
                </span>
              </div>
            </div>
            <div class="cell__col has-max-width">
              <p class="has-font-100" [attr.data-cy-card-text]="halfCard.id">{{halfCard.celltext}}</p>
            </div>
            <div class="cell__col">
              <i class="sd-icon">angle_right</i>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>
