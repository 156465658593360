<div class="card">
    <span class="has-font-200 is-bold" data-cy="submittals">Vorlagen, Dokumente</span>
    <div class="cell has-divider--inset" *ngFor="let cell of cells | slice:0:3; let last = last" [ngClass]="{ 'last' : last, 'add-divider' : showMore === true}" data-cy="file-row">
        <a [href]="cell.downloadUrl" download data-cy="file-download">
          <div class="cell__row cell-size-200 cell--clickable" tabindex="0">
            <div class="cell__col" data-cy="">
                <i class="sd-icon">file_pdf</i>
            </div>
            <div class="cell__col has-max-width" data-cy="file-text">{{cell.celltext}}</div>
            <div class="cell__col">
                <i class="sd-icon">download</i>
            </div>
          </div>
        </a>
    </div>
    <ng-container *ngIf="showMore">
      <ng-container *ngFor="let cell of cells; let ndx = index; let last = last">
        <div class="cell has-divider--inset" *ngIf="ndx >=3" [ngClass]="{'last' : last}">
          <a [href]="cell.downloadUrl" download>
            <div class="cell__row cell-size-200 cell--clickable" tabindex="0">
              <div class="cell__col">
                  <i class="sd-icon" data-cy="pdf-icon">file_pdf</i>
              </div>
              <div class="cell__col has-max-width">{{cell.celltext}}</div>
              <div class="cell__col">
                  <i class="sd-icon">download</i>
              </div>
            </div>
          </a>
      </div>
      </ng-container>
    </ng-container>
</div>

<a (click)="showMore = true" class="link link--primary" [ngClass]="{'display-none' : showMore === true}" data-cy="show-all">Alle ansehen</a>