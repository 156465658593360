import { Component, Input, OnDestroy } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { ProductSelectionService } from '@core/services/product-selection.service';
import { TealiumService } from '@core/services/tealium.service';
import { UrlService } from '@core/services/url.service';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { ModalContainerService } from './../../../modal-container.service';
import { ModalType } from './../../../modal-type.enum';

@Component({
  selector: 'app-extend-runtime',
  templateUrl: './extend-runtime.component.html',
  styleUrls: ['./extend-runtime.component.scss']
})
export class ExtendRuntimeComponent implements OnDestroy {
  public activationTextCaption: string | undefined;

  private _userObject: UserObject;

  @Input()
  public set userObject(value: UserObject) {
    this.activationTextCaption = this.getActivationText(value);
    this._userObject = value;
  }

  public get userObject(): UserObject {
    return this._userObject;
  }

  private readonly subscriptions = new Subscription();

  constructor(
    private readonly modalContainerService: ModalContainerService,
    private readonly tealiumService: TealiumService,
    private readonly productSelectionService: ProductSelectionService,
    private readonly urlService: UrlService
  ) {}

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public onClickActivate(): void {
    if (this.userObject.isSingleRoomInApartment) {
      this.modalContainerService.setModalType(ModalType.ActivateSharedApartment);
      return;
    }

    if (this.wasNeverActive(this.userObject)) {
      this.activateForTheFirstTime(this.userObject.globalObjectKey);
    } else {
      this.modalContainerService.setModalType(ModalType.Activate);
    }
  }

  private wasNeverActive(userObject: UserObject): boolean {
    return !userObject.visible && !userObject.subscription && !userObject.runtimeUntil;
  }

  private activateForTheFirstTime(globalObjectKey: string): void {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'laufzeit anzeige aktivieren_erstaktivierung'
    });

    this.subscriptions.add(
      this.productSelectionService
        .createSession(globalObjectKey)
        .pipe(
          map(result => result.redirectUrl),
          filter(redirectUrl => !!redirectUrl)
        )
        .subscribe(redirectUrl => this.urlService.navigateToExternalUrl(redirectUrl))
    );
  }

  private getActivationText(userObject: UserObject): string | undefined {
    if (this.isSubscriptionExtendable(userObject)) {
      return 'Laufzeit verlängern';
    }

    if (this.wasNeverActive(userObject)) {
      return 'Anzeige aktivieren';
    }

    return undefined;
  }

  private isSubscriptionExtendable(userObject: UserObject): boolean {
    const noSubscriptionButRemainingRuntime = this.hasNoSubscriptionButRemainingRuntime(userObject);
    const subscriptionWithRuntimeInPast = this.hasSubscriptionWithRuntimeInPast(userObject);
    return noSubscriptionButRemainingRuntime || subscriptionWithRuntimeInPast;
  }

  private hasNoSubscriptionButRemainingRuntime(userObject: UserObject): boolean {
    return !userObject.subscription && userObject.runtimeUntil !== undefined;
  }

  private hasSubscriptionWithRuntimeInPast(userObject: UserObject): boolean {
    return userObject.subscription && userObject.runtimeUntil !== undefined && !userObject.isRuntimeInFuture && !userObject.visible;
  }
}
