import { Component, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { AlertText } from '@core/models/enums/alert-key.enum';
import { AlertType } from '@core/models/enums/alert-type.enum';
import { Icon } from '@core/models/enums/icon.enum';
import { UserObject } from '@core/models/user-object.interface';
import { ObjectService } from '@core/services/object.service';
import { TealiumService } from '@core/services/tealium.service';
import { ButtonComponent } from '@smartdesign/angular';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-cancel-modal',
  templateUrl: './cancel-modal.component.html',
  styleUrls: ['./cancel-modal.component.scss']
})
export class CancelModalComponent implements OnDestroy {
  private readonly subscription = new Subscription();

  public readonly AlertText = AlertText;
  public readonly AlertType = AlertType;
  public readonly Icon = Icon;
  public cancellationInProgress: boolean;
  public subscriptionError: boolean;
  public subscriptionCancelProcessDone: boolean;
  public modalContentCaption = 'Vertrag beenden';
  public modalContentSubCaption = 'Möchtest du den Vertrag wirklich beenden?';
  public modalContentText = `Du kannst den Vertrag jederzeit mit einer Frist von einem Tag zum Ende der ursprünglichen Laufzeit bzw.
  zum Ende eines Zyklus, der mit dem Ende der ursprünglichen Laufzeit beginnt, kündigen.
  Nach erfolgreicher Kündigung erhältst du eine Bestätigung per E-Mail.`;

  @ViewChild('cancellationButton')
  public cancellationButton: ButtonComponent;

  @Input()
  public userObject: UserObject;

  @Input()
  public displayMode: string;

  @Input()
  public flexEnd: string;

  @Output()
  public readonly closeModal = new EventEmitter<void>();

  constructor(
    private readonly objectService: ObjectService,
    private readonly tealiumService: TealiumService
  ) {}

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public onCancelClick(): void {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'verlaengerung beenden_jetzt kuendigen'
    });
    this.cancellationInProgress = true;

    this.subscription.add(
      this.objectService.subscriptionCancel(this.userObject.globalObjectKey).subscribe((result) => {
        if (result.success) {
          this.switchModalContent();
          this.subscriptionCancelProcessDone = true;
          this.subscriptionError = false;
          this.cancellationInProgress = false;
          this.userObject.subscription = false;
        } else {
          this.subscriptionCancelProcessDone = false;
          this.subscriptionError = true;
          this.cancellationInProgress = false;
        }
      })
    );
  }

  private switchModalContent(): void {
    this.modalContentCaption = 'Beendigung erfolgreich';
    this.modalContentSubCaption = 'Wir haben deine Kündigung erhalten.';
    this.modalContentText = 'Du erhältst eine Bestätigung deiner Kündigung per E-Mail.';
  }
}
