<h2 id="object-list-headline" class="is-bold mt-250 mt-200:400 mb-250 mb-200:400">{{distributionTypeHeadline}}</h2>
<app-alertbox *ngIf="objectError" [alertText]="AlertText.ObjectError"></app-alertbox>
<app-empty-list *ngIf="!hasListItems; else objectList"></app-empty-list>
<ng-template #objectList>
    <div
      *ngFor="let item of items; let first = first; let last = last"
      [ngClass]="{ 'first' : first, 'last' : isMobile && last && !isShowMoreVisible }"
      data-cy="listing">
        <app-object [item]="item"></app-object>
    </div>
</ng-template>
<app-show-more *ngIf="isShowMoreVisible"></app-show-more>
