import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';

@Component({
  selector: 'app-edit-modal',
  templateUrl: './edit-modal.component.html',
  styleUrls: ['./edit-modal.component.scss']
})
export class EditModalComponent {
  @Input()
  public userObject: UserObject;

  @Output()
  public readonly closeModal = new EventEmitter<void>();
}
