import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ModalPopupComponent } from '@shared/components/modal-popup/modal-popup.component';
import { SmartDesignAngularModule } from '@smartdesign/angular';
import { CoreModule } from './../core/core.module';
import { AlertboxFixedComponent } from './components/alertbox-fixed/alertbox-fixed.component';
import { AlertboxIeComponent } from './components/alertbox-ie/alertbox-ie.component';
import { AlertboxComponent } from './components/alertbox/alertbox.component';
import { CancelTextComponent } from './components/cancel-text/cancel-text.component';
import { PriceTableComponent } from './components/price-table/price-table.component';
import { RuntimeTextComponent } from './components/runtime-text/runtime-text.component';


@NgModule({
  declarations: [
      AlertboxComponent,
      AlertboxFixedComponent,
      AlertboxIeComponent,
      ModalPopupComponent,
      PriceTableComponent,
      RuntimeTextComponent,
      CancelTextComponent
  ],
  exports:[
    AlertboxComponent,
    AlertboxFixedComponent,
    AlertboxIeComponent,
    ModalPopupComponent,
    PriceTableComponent,
    RuntimeTextComponent,
    CancelTextComponent
  ],
  imports: [
    CommonModule,
    SmartDesignAngularModule,
    CoreModule
  ],
  providers: []
})
export class SharedModule {}
