<div class="avatar-group">
    <div class="avatar avatar--400">
        <img class="avatar__img" [src]="avatarFace1" data-cy="avatar-face-1"/>
    </div>
    <div class="avatar avatar--400">
        <img class="avatar__img" [src]="avatarFace2" data-cy="avatar-face-2"/>
    </div>
    <div class="avatar avatar--400">
        <img class="avatar__img" [src]="avatarFace3" data-cy="avatar-face-3"/>
    </div>
</div>