import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ObjectState } from '@core/models/object-state.interface';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, map, timeout } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class ObjectService {
  constructor(private readonly httpClient: HttpClient) {}

  public subscriptionCancel(globalObjectKey: string) {
    const url = environment.cloudGatewayUrl + 'owner-dashboard/v1/object/' + globalObjectKey + '/subscription/cancel';

    return this.httpClient.put(url, {}, { observe: 'response' }).pipe(
      map((response) => {
        const success = response.status === 204;
        return { success };
      }),
      timeout(8500),
      catchError((error) => this.handleError(error))
    );
  }

  public deleteObject(globalObjectKey: string) {
    const url = environment.cloudGatewayUrl + 'owner-dashboard/v1/object/' + globalObjectKey;

    return this.httpClient.delete(url, { observe: 'response' }).pipe(
      map((response) => response.status === 204),
      timeout(8500),
      catchError(() => of(false))
    );
  }

  public activateObject(globalObjectKey: string) {
    return this.httpClient
      .put(environment.cloudGatewayUrl + 'owner-dashboard/v1/object/' + globalObjectKey + '/activate', {}, { observe: 'response' })
      .pipe(
        map((response) => response.status === 204),
        timeout(8500),
        catchError(() => of(false))
      );
  }

  public deactivateObject(globalObjectKey: string) {
    const url = environment.cloudGatewayUrl + 'owner-dashboard/v1/object/' + globalObjectKey + '/deactivate';

    return this.httpClient.put(url, {}, { observe: 'response' }).pipe(
      map((response) => response.status === 204),
      timeout(8500),
      catchError(() => of(false))
    );
  }

  public getState(globalObjectKey: string): Observable<ObjectState> {
    const url = environment.cloudGatewayUrl + 'owner-dashboard/v1/object/' + globalObjectKey + '/state';
    return this.httpClient.get<ObjectState>(url);
  }

  private handleError(error: unknown) {
    let errorKey = 'UNKNOWN';

    if (error instanceof HttpErrorResponse) {
      switch (error.status) {
        case 0:
          errorKey = 'OFFLINE';
      }
    }

    return of({ success: false, error: errorKey });
  }
}
