<div *ngIf="!neverActive"
    class="cell__row cell-size-300 cell--clickable"
    (click)="onActivateSwitch()"
    data-cy="activate-switch">

    <div    class="cell__col has-max-width"
            data-cy="link-status">

        <p  class="has-font-100"
            data-cy="status-text">
            {{objectStatusText}}
        </p>

        <p  class="has-font-75 color-grey-500"
            data-cy="status-subtext">
            {{objectStatusSubtext}}
        </p>
    </div>
    <div class="cell__col">
        <div *ngIf="!userObject?.isSingleRoomInApartment"
            class="switch">

            <label  class="switch__wrapper"
                    data-cy="toggle">
              <input type="checkbox"
                     class="switch__checkbox"
                     data-cy="toggle-status"
                     [checked]="isObjectActive"
                     [disabled]="deactivateToggle">
                <span class="switch__slider"></span>
            </label>
        </div>
        <i *ngIf="userObject?.isSingleRoomInApartment"
            class="sd-icon">
            angle_right
        </i>
    </div>
</div>
<div *ngIf="activationError"
      class="cell__row cell-size-300">
    <div class="cell__col has-max-width">
        <app-alertbox [alertText]="AlertText.ActivationError">
        </app-alertbox>
    </div>
</div>
