import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alertbox-fixed',
  templateUrl: './alertbox-fixed.component.html',
  styleUrls: ['./alertbox-fixed.component.scss']
})
export class AlertboxFixedComponent implements OnInit {
  @Input() alertKey: string;
  fixedAlertText: string;

  constructor() {}

  ngOnInit(): void {
    if (this.alertKey === 'OFFLINE_ERROR') {
      this.fixedAlertText = 'Du bist offline. Bitte überprüfe deine Netzwerkverbindung.';
    }
    if (this.alertKey === 'GENERAL_ERROR') {
      this.fixedAlertText = 'Ohje, das hat leider nicht geklappt. Bitte versuche es erneut.';
    }
  }
}
