import { CommonModule, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { AutomatedTestGuard } from '@core/guards/automated-test.guard';
import { GlobalErrorHandler } from '@core/handler/global-error.handler';
import { TokenInterceptor } from '@core/interceptor/token.interceptor';
import { ObjectProviderService } from '@core/services/object-provider.service';
import { ErrorPageComponent } from '@shared/components/error-page/error-page.component';
import { SharedModule } from '@shared/shared.module';
import { SmartDesignAngularModule } from '@smartdesign/angular';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AutomatedTestComponent } from './pages/automated-test/automated-test.component';
import { CalculatorsComponent } from './pages/home/components/calculators/calculators.component';
import { SliderComponent, SliderItemDirective, SliderItemElementDirective } from './pages/home/components/calculators/slider/slider.component';
import { CardGroupComponent } from './pages/home/components/card-group/card-group.component';
import { CardLinksComponent } from './pages/home/components/card-links/card-links.component';
import { FootnoteComponent } from './pages/home/components/footnote/footnote.component';
import { ModalContainerModule } from './pages/home/components/modal-container/modal-container.module';
import { ObjectListHeaderComponent } from './pages/home/components/object-list-header/object-list-header.component';
import { EmptyListComponent } from './pages/home/components/object-list/empty-list/empty-list.component';
import { EditButtonComponent } from './pages/home/components/object-list/object/edit-button/edit-button.component';
import { InfoCardComponent } from './pages/home/components/object-list/object/info-card/info-card.component';
import { ObjectComponent } from './pages/home/components/object-list/object/object.component';
import { ShowMoreComponent } from './pages/home/components/object-list/show-more/show-more.component';
import { SalutationComponent } from './pages/home/components/salutation/salutation.component';
import { AvatarGroupComponent } from './pages/home/components/support/avatar-group/avatar-group.component';
import { SupportComponent } from './pages/home/components/support/support.component';
import { TabsComponent } from './pages/home/components/tabs/tabs.component';
import { TemplatesDocumentsComponent } from './pages/home/components/templates-documents/templates-documents.component';
import { HomeComponent } from './pages/home/home.component';
import { PreviewAlertComponent } from './pages/object-preview/components/preview-alert/preview-alert.component';
import { ObjectPreviewComponent } from './pages/object-preview/object-preview.component';
import { ObjectListFooterComponent } from './pages/home/components/object-list-footer/object-list-footer.component';
import { PageFooterComponent } from './pages/home/components/page-footer/page-footer.component';
import { ObjectListComponent } from './pages/home/components/object-list/object-list.component';

registerLocaleData(localeDe);

@NgModule({
  declarations: [
    AppComponent,
    SalutationComponent,
    SupportComponent,
    CardLinksComponent,
    HomeComponent,
    AutomatedTestComponent,
    CardGroupComponent,
    EmptyListComponent,
    ObjectComponent,
    ShowMoreComponent,
    EditButtonComponent,
    InfoCardComponent,
    TabsComponent,
    CalculatorsComponent,
    SliderComponent,
    SliderItemDirective,
    SliderItemElementDirective,
    FootnoteComponent,
    AvatarGroupComponent,
    TemplatesDocumentsComponent,
    ObjectPreviewComponent,
    PreviewAlertComponent,
    ErrorPageComponent,
    ObjectListHeaderComponent,
    ObjectListFooterComponent,
    PageFooterComponent,
    ObjectListComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SmartDesignAngularModule,
    CoreModule,
    BrowserAnimationsModule,
    ModalContainerModule,
    SharedModule
  ],
  providers: [
    AutomatedTestGuard,
    AuthenticationGuard,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    ObjectProviderService
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {}
