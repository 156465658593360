import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {
  constructor(@Inject(DOCUMENT) private readonly document: Document) {}

  public addScript(url: string, tag: string) {
    const node = this.document.createElement('script');
    node.src = url;
    node.type = 'text/javascript';
    node.async = true;
    this.document.getElementsByTagName(tag)[0].appendChild(node);
    return node;
  }
}
