<div class="tab tab--fixed tab--size-100">
    <div class="tab__container">
        <div class="tab__items">
            <button id="dt-rent" class="tab__item" type="button" role="tab" [ngClass]="{'is-active is-active-bar': distributionType  === distributionTypeEnum.rent}" (click)="changeDistributionType(distributionTypeEnum.rent)">
                <div class="tab__text" title="First">Vermieten</div>
                <div class="tab__indicator-static"></div>
            </button>
            <button id="dt-buy" class="tab__item" type="button" role="tab" [ngClass]="{'is-active is-active-bar': distributionType  === distributionTypeEnum.buy}" (click)="changeDistributionType(distributionTypeEnum.buy)">
                <div class="tab__text" title="Second">Verkaufen</div>
                <div class="tab__indicator-static"></div>
            </button>
        </div>
        <div class="tab__indicator-animated"></div>
        <div class="tab__border-full"></div>
    </div>
</div>
