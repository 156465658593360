import { Injectable } from '@angular/core';
import { UserObject } from '../../../core/models/user-object.interface';

@Injectable({
  providedIn: 'root'
})
export class PreviewLinkService {

  public openPreview(item: UserObject | undefined): void {
    let url;
    const isLocal = location.hostname === 'localhost';

    if(item === undefined){
      return;
    }

    if (item.visible) {
      url = `/expose/${item.onlineId}`;
    } else {
      url = isLocal
        ? `/preview/${item.globalObjectKey}`
        : `/eigentuemerwelt/home/preview/${item.globalObjectKey}`;
    }

    window.open(url, '_blank');
  }

}
