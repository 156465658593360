<div class="modal"
    data-cy="edit-object-modal"
    [ngClass]="{'modal--is-shown': modalContainerService.isModalVisible$ | async}">

    <div class="modal__dialog"
         *ngIf="modalContainerService.userObject$ | async as userObject"
         [ngSwitch]="modalContainerService.modalType$ | async">

        <app-edit-modal *ngSwitchCase="ModalType.Edit"
                        [userObject]="userObject"
                        (closeModal)="closeModal()">
        </app-edit-modal>

        <app-cancel-modal *ngSwitchCase="ModalType.Cancel"
                          [userObject]="userObject"
                          (closeModal)="closeModal()">
        </app-cancel-modal>

        <app-delete-object-content  *ngSwitchCase="ModalType.Delete"
                                    [userObject]="userObject"
                                    (closeModalEvent)="closeModal()">
        </app-delete-object-content>

        <app-deactivation-modal *ngSwitchCase="ModalType.Deactivate"
                                [userObject]="userObject"
                                (closeModalEvent)="closeModal()">
        </app-deactivation-modal>

        <app-deactivate-shared-apartment-modal *ngSwitchCase="ModalType.DeativateSharedAppartment"
                                                [userObject]="userObject"
                                                (closeModalEvent)="closeModal()">
        </app-deactivate-shared-apartment-modal>

        <app-activate-ad    *ngSwitchCase="ModalType.Activate"
                            [item]="userObject"
                            (closeModalEvent)="closeModal()">
        </app-activate-ad>

        <app-activate-shared-apartment-modal *ngSwitchCase="ModalType.ActivateSharedApartment"
                                             [userObject]="userObject"
                                            (closeModalEvent)="closeModal()">
        </app-activate-shared-apartment-modal>
    </div>
</div>
