<div *ngIf="isVisible" class="actionbar is-top actionbar--on-dark">
  <div class="actionbar__content">
    <div class="flex mr-100 ml-100">
      <div class="flex-1">
        <div class="cell">
          <div class="cell__row cell-size-300">
            <div class="cell__col">
              <span class="sd-icon" sdUiIcon>eye_off</span>
            </div>
            <div class="cell__col has-no-gutter">
              <h3 data-cy="preview-alert-text">Vorschau-Modus</h3>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-0 flex items-center justify-end">
        <sd-button
          *ngIf="showCloseTabButton"
          (click)="closeWindow()"
          [variant]="'secondary'"
          [onDark]="true"
          [size]="50">Beenden</sd-button>
      </div>
    </div>
  </div>
</div>
