<div class="modal__container" data-cy="confirmation-modal">
    <div class="modal__title">
        <h2 class="modal__title-text mb-100" data-cy="headline-confirmation-modal">{{headline}}</h2>
        <button type="button" class="icon-btn icon-btn--50 modal__close" (click)="closeModal.emit()" [disabled]="buttonsDisabled" data-cy="close-confirmation-modal-button">
            <span class="sd-icon">{{Icon.Close}}</span>
        </button>
    </div>
    <div class="modal__content">
        <div class="modal__content-inner" data-cy="confirmation-modal-content">
            <ng-content></ng-content>
        </div>
        <app-alertbox [alertText]="alertText" *ngIf="alertText"></app-alertbox>
    </div>
    <div class="modal__buttons">
        <sd-button [fullWidth]="200" (click)="cancel.emit()" [variant]="'tertiary'" [disabled]="buttonsDisabled" data-cy="cancel-modal-button">{{cancelButtonText}} </sd-button>
        <sd-button [fullWidth]="200" (click)="confirm.emit()" [loading]="buttonsDisabled" [danger]="isConfirmButtonRed" [disabled]="buttonsDisabled || confirmButtonDisabled" data-cy="confirm-modal-button">{{confirmButtonText}}</sd-button>
    </div>
</div>
