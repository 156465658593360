<app-confirmation-modal
    [headline]="'Anzeige löschen'"
    [confirmButtonText]="'Löschen'"
    [alertText]="alertText"
    [buttonsDisabled]="deletionInProgress"
    [isConfirmButtonRed]="true"
    (closeModal)="closeModalEvent.emit()"
    (cancel)="closeModalEvent.emit()"
    (confirm)="deleteObject()">

    <p  class="text-100 mb-100"
        data-cy="delete-question">
        Möchtest du deine Anzeige wirklich löschen?
    </p>

</app-confirmation-modal>
