import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CookieService {
  domain: string;

  constructor(@Inject(DOCUMENT) private document: Document) {
    const hostWithSubdomain = this.document.location.hostname;
    const hostWithoutSubdomain = hostWithSubdomain.split('.').reverse().slice(0, 2).reverse();
    this.domain = hostWithoutSubdomain.join('.');
  }

  public getCookieByName(name: string) {
    const match = this.document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) {
      return match[2];
    } else {
      return '';
    }
  }

  public createSessionCookie(name: string, value: string) {
    this.document.cookie = name + '=' + value + ';domain=' + this.domain + ';path=/';
  }
}
