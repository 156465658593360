import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'badgeColor'
})
export class BadgeColorPipe implements PipeTransform {
  transform(activationRequest: boolean, visible: boolean) {
    if (activationRequest) {
      return 'badge--brandyellow';
    } else if (visible) {
      return 'badge--added';
    } else {
      return 'badge--removed';
    }
  }
}
