import { Component } from '@angular/core';
import { UserObjectService } from '@core/services/user-object.service';

@Component({
  selector: 'app-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss']
})
export class ShowMoreComponent {

  constructor(private readonly userObjectService: UserObjectService) {}

  public getMoreListings(): void {
    this.userObjectService.loadMoreListings();
  }
}
