import { Component, OnInit, ViewChild } from '@angular/core';
import { DistributionTypeEnum } from '@core/models/enums/distribution-type.enum';
import { DistributionTypeService } from '@core/services/distribution-type.service';
import { MobileService } from '@core/services/mobile.service';
import { ResizeService } from '@core/services/resize.service';
import { TealiumService } from '@core/services/tealium.service';
import { environment } from '@environments/environment';
import { Subscription } from 'rxjs';

import { SliderComponent } from './slider/slider.component';

interface SliderItem {
  readonly event: string;
  readonly imageUrl: string;
  readonly imageMobileUrl: string;
  readonly showForDistributionType: string;
  url: string;
}

@Component({
  selector: 'app-calculators',
  templateUrl: './calculators.component.html',
  styleUrls: ['./calculators.component.scss']
})
export class CalculatorsComponent implements OnInit {
  @ViewChild('mobileSlider') private mobileSlider: SliderComponent;
  currentSliderItems: Array<SliderItem> = [];

  distributionType: string;
  isMobile = false;
  subscription: Subscription;
  swipeCoord?: [number, number];
  swipeTime?: number;

  private readonly allSliderItems: Array<SliderItem> = [
    {
      event: 'rental-calculator',
      imageUrl: environment.assetsUrl + 'assets/mietpreisrechner.png',
      imageMobileUrl: environment.assetsUrl + 'assets/mietpreisrechner_mobile.svg',
      url: 'https://www.immowelt.de/mietpreisrechner',
      showForDistributionType: DistributionTypeEnum.rent
    },
    {
      event: 'real-estate-assessment',
      imageUrl: environment.assetsUrl + 'assets/immobilienbewertung.png',
      imageMobileUrl: environment.assetsUrl + 'assets/immobilienbewertung_mobile.svg',
      url: 'https://www.immowelt.de/anbieten/immobilienbewertung',
      showForDistributionType: DistributionTypeEnum.buy
    },
    {
      event: 'price-finder',
      imageUrl: environment.assetsUrl + 'assets/immobilienpreise.png',
      imageMobileUrl: environment.assetsUrl + 'assets/immobilienpreise_mobile.svg',
      url: 'https://www.immowelt.de/immobilienpreise/deutschland/hauspreise',
      showForDistributionType: 'ALL'
    },
    {
      event: 'demand-calculator',
      imageUrl: environment.assetsUrl + 'assets/nachfrage.png',
      imageMobileUrl: environment.assetsUrl + 'assets/nachfrage_mobile.svg',
      url: 'https://www.immowelt.de/myiw/meineimmowelt/statistik/demandstatisticstart.aspx',
      showForDistributionType: DistributionTypeEnum.rent
    }
  ];

  constructor(
    private readonly distributionTypeService: DistributionTypeService,
    private readonly mobileService: MobileService,
    private readonly resizeService: ResizeService,
    private readonly tealiumService: TealiumService
  ) {
  }

  ngOnInit(): void {
    this.isMobile = this.mobileService.checkIsMobile();
    this.subscription = this.resizeService.observable.subscribe(() => {
      const isMobile = this.mobileService.checkIsMobile();
      if (isMobile) {
        this.isMobile = isMobile;
      } else {
        this.isMobile = window.innerWidth < 800;
      }
    });

    this.distributionType = this.distributionTypeService.get();
    this.distributionTypeService.getAsObservable().subscribe((distributionType) => {
      this.onDistributionTypeChanged(distributionType);
    });

    this.setSliderItems(this.distributionType);
  }

  swipe(e: TouchEvent, when: string) {
    const coord: [number, number] = [e.changedTouches[0].clientX, e.changedTouches[0].clientY];
    const time = new Date().getTime();

    if (when === 'start') {
      this.swipeCoord = coord;
      this.swipeTime = time;
    } else if (when === 'end') {
      const first = coord[0] - (this.swipeCoord?.[0] ?? 0);
      const second = coord[1] - (this.swipeCoord?.[1] ?? 0);
      const direction = [first, second];
      const duration = time - (this.swipeTime ?? 0);

      if (
        duration < 1000 && //
        Math.abs(direction[0]) > 30 && // Long enough
        Math.abs(direction[0]) > Math.abs(direction[1] * 3)
      ) {
        // Horizontal enough
        const swipe = direction[0] < 0 ? 'next' : 'previous';
        if (swipe === 'next') {
          this.mobileSlider.next();
        } else if (swipe === 'previous') {
          this.mobileSlider.prev();
        }
      }
    }
  }

  tealiumLink(event: string) {
    let label = '';
    switch (event) {
      case 'rental-calculator':
        label = 'tile_mietpreisrechner';
        break;
      case 'real-estate-assessment':
        label = 'tile_immobilienbewertung starten';
        break;
      case 'price-finder':
        label = 'tile_immobilienpreise herausfinden';
        break;
      case 'demand-calculator':
      default:
        label = 'tile_nachfrage berechnen';
        break;
    }
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: label
    });
  }

  private onDistributionTypeChanged(distributionType: string) {
    this.distributionType = distributionType;
    this.setSliderItems(this.distributionType);
    this.mobileSlider?.reset(this.currentSliderItems);
  }

  private setSliderItems(distributionType: string) {
    this.currentSliderItems = this.allSliderItems.filter(
      (x) => x.showForDistributionType === distributionType || x.showForDistributionType === 'ALL'
    );
  }
}
