import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UserObject } from '@core/models/user-object.interface';
import { format, parseISO } from 'date-fns';
import { RuntimeTextSelection } from './runtime-text-selection.enum';

@Component({
  selector: 'app-runtime-text',
  templateUrl: './runtime-text.component.html',
  styleUrls: ['./runtime-text.component.scss']
})
export class RuntimeTextComponent implements OnChanges {

  public RuntimeTextSelection = RuntimeTextSelection;

  @Input()
  public item: UserObject;
  public runtimeUntilReadable: string | undefined;

  public ngOnChanges(changes: SimpleChanges): void {
    if(changes.item){
      this.runtimeUntilReadable = undefined;
      this.setRuntimeUntilReadable();
    }
  }

  private setRuntimeUntilReadable() {
    if (this.item && this.item.runtimeUntil) {
      const runtimeUntil = parseISO(this.item.runtimeUntil);
      this.runtimeUntilReadable = format(runtimeUntil, 'dd.MM.yyyy');
    }
  }

  public getSwitchExpression() {
    if (this.item) {
      if(this.item.isSingleRoomInApartment){
        return RuntimeTextSelection.NotPublished;
      }

      if (this.runtimeUntilReadable && this.item.subscription && this.item.isRuntimeInFuture && !this.item.visible) {
        return RuntimeTextSelection.InactiveWithSubscriptionInFuture;
      }

      if (this.runtimeUntilReadable && !this.item.subscription && this.item.isRuntimeInFuture) {
        return RuntimeTextSelection.InactiveWithNoSubscriptionInFuture;
      }

      if (this.runtimeUntilReadable && !this.item.subscription && !this.item.isRuntimeInFuture) {
        return RuntimeTextSelection.InactiveWithNoSubscriptionInPast;
      }

      if (this.item.visible && !this.item.subscription && !this.item.isRuntimeInFuture) {
        return RuntimeTextSelection.ActiveWithNoSubscriptionInPast;
      }
    }

    return RuntimeTextSelection.NeverActive;
  }
}
