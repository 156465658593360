    <div id="support" class="support has-text-centered">
      <app-avatar-group></app-avatar-group>
        <h2 class="has-font-400 is-bold" data-cy="support-title">Deine Eigentümer-Experten</h2>
        <p class="has-font-200 support__text" id="support-description" data-cy="support-text">
          Unser Service für private Eigentümer ist von <span class="is-bold">Montag bis Freitag, 8-18 Uhr</span>
          gerne für dich da.
        </p>
        <div class="row">
          <div class="col col-500-6 col-400-6 col-300-12 col-200-12" *ngFor="let item of items">
            <div class="teaser teaser--centered" *ngIf="item.id !== 'chat'">
              <div class="teaser__left-content">
                <div class="teaser__icon-background" attr.data-cy="{{ item.id }}-icon">
                  <span class="sd-icon">{{ item.icon }}</span>
                </div>
              </div>
              <div class="teaser__content">
                <div class="teaser__headline" attr.data-cy="{{ item.id }}-text">{{ item.text }}</div>
                <a href="{{item.link}}" class="has-font-100 link link--secondary"
                  attr.data-cy="{{ item.id }}-link">{{item.linktext}}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
