import { Component, OnDestroy, OnInit } from '@angular/core';
import { ContactRequestInboxItem } from '@core/models/contact-request-inbox-item.interface';
import { AlertText } from '@core/models/enums/alert-key.enum';
import { DistributionTypeEnum } from '@core/models/enums/distribution-type.enum';
import { UserObjectPagination } from '@core/models/user-object-pagination.interface';
import { UserObject } from '@core/models/user-object.interface';
import { ContactRequestInboxService } from '@core/services/contact-request-inbox.service';
import { DistributionTypeService } from '@core/services/distribution-type.service';
import { MobileService } from '@core/services/mobile.service';
import { UserObjectService } from '@core/services/user-object.service';
import { forkJoin, Subscription } from 'rxjs';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-object-list',
  templateUrl: './object-list.component.html',
  styleUrls: ['./object-list.component.scss']
})
export class ObjectListComponent implements OnInit, OnDestroy {
  public items: UserObject[];
  public distributionTypeHeadline: string;
  public objectError = false;
  public hasListItems = false;
  public isShowMoreVisible = false;
  public isMobile = false;
  public readonly AlertText = AlertText;

  private hasLoadedObjectsInitially = false;
  private distributionType: DistributionTypeEnum;
  private readonly subscriptions: Subscription = new Subscription();

  constructor(
    private readonly distributionTypeService: DistributionTypeService,
    private readonly userObjectService: UserObjectService,
    private readonly contactRequestInboxService: ContactRequestInboxService,
    private readonly mobileService: MobileService
  ) {}

  public ngOnInit(): void {
    this.distributionType = this.distributionTypeService.initialize();
    this.isMobile = this.mobileService.checkIsMobile();

    this.initSubscriptions();
    this.initialObjectLoad();
  }

  public ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  private initSubscriptions(): void {
    this.initDistributionTypeSubscription();
    this.listenToObjectChanges();
  }

  private initDistributionTypeSubscription(): void {
    this.subscriptions.add(
      this.distributionTypeService.getAsObservable().subscribe((distributionType) => {
        this.onDistributionTypeChanged(distributionType);
        if (distributionType === DistributionTypeEnum.buy) {
          this.distributionTypeHeadline = 'Immobilien zum Verkauf';
        }
        if (distributionType === DistributionTypeEnum.rent) {
          this.distributionTypeHeadline = 'Immobilien zur Vermietung';
        }
      })
    );
  }

  private listenToObjectChanges(): void {
    this.subscriptions.add(
      this.userObjectService.userObjectPagination$.subscribe((value) => {
        this.hasListItems = value.items.length > 0;
        this.items = value.items;
        this.isShowMoreVisible = value.next !== undefined;
        this.addContactRequestsToUserObjects(value);
      })
    );
  }

  private onDistributionTypeChanged(distributionType: DistributionTypeEnum) {
    this.distributionType = distributionType;
    this.items = [];

    // Only load objects from the user object service if the inital call to the service was already done
    // If not done like this there could be multiple API calls instead of loading item lists from the cache
    if (this.hasLoadedObjectsInitially) {
      this.subscriptions.add(
        this.userObjectService.loadUserObjectsFromService(distributionType).subscribe(
          () => {},
          () => {
            this.objectError = true;
          }
        )
      );
    }
  }

  private addContactRequestsToUserObjects(value: UserObjectPagination) {
    if (this.checkForNewItems(value)) {
      const globalObjectKeyArray = this.initGlobalObjectKeyArray(value.items);
      this.subscriptions.add(
        this.contactRequestInboxService.getCount(globalObjectKeyArray).subscribe(
          (contactRequestInboxItems) => {
            this.mapContactRequestInboxItems(contactRequestInboxItems, value.items);
          },
          (error) => {
            this.setFallbackContactRequestInboxItems(value.items);
            throw error;
          }
        )
      );
    }
  }

  private checkForNewItems(value: UserObjectPagination): boolean {
    return value?.items?.length > 0 && !value?.fromCache;
  }

  private initGlobalObjectKeyArray(items: UserObject[]): string[] {
    return items.map((x) => x.globalObjectKey);
  }

  private mapContactRequestInboxItems(contactRequestInboxItems: ContactRequestInboxItem[], items: UserObject[]): void {
    for (const contactRequestInboxItem of contactRequestInboxItems) {
      const item = items.find((x) => x.globalObjectKey === contactRequestInboxItem.globalObjectId);
      if (item) {
        item.contactRequestInboxItem = contactRequestInboxItem;
      }
    }
  }

  private setFallbackContactRequestInboxItems(items: UserObject[]): void {
    for (const item of items) {
      item.contactRequestInboxItem = { globalObjectId: item.globalObjectKey, total: -1, notViewed: -1 };
    }
  }

  private initialObjectLoad(): void {
    let hasBuyItems = false;
    let hasRentItems = false;

    this.subscriptions.add(
      forkJoin({
        // Load rent objects and don't send events
        rent: this.userObjectService.loadUserObjectsFromService(DistributionTypeEnum.rent, false),
        // Load buy objects and don't send events
        buy: this.userObjectService.loadUserObjectsFromService(DistributionTypeEnum.buy, false)
      })
        .pipe(
          finalize(() => {
            // This finally sets the distribution type to RENT or BUY to trigger the onDistributionTypeChanged(...)
            // that was delayed because of this initial object load
            this.setDistributionTypeByLoadedItems(hasBuyItems, hasRentItems);
          })
        )
        .subscribe((values) => {
          hasRentItems = this.initLoadedValue(values.rent as UserObjectPagination);
          hasBuyItems = this.initLoadedValue(values.buy as UserObjectPagination);
        })
    );
  }

  private setDistributionTypeByLoadedItems(hasBuyItems: boolean, hasRentItems: boolean): void {
    this.hasLoadedObjectsInitially = true;
    if (this.hasDistributionTypeToBeBuy(hasBuyItems, hasRentItems)) {
      this.distributionTypeService.set(DistributionTypeEnum.buy);
    } else if (hasRentItems) {
      this.distributionTypeService.set(DistributionTypeEnum.rent);
    }
  }

  private hasDistributionTypeToBeBuy(hasBuyItems: boolean, hasRentItems: boolean): boolean {
    return (
      (this.distributionType === DistributionTypeEnum.buy && hasBuyItems) ||
      this.hasDistributionTypeToBeSwitchedToBuy(hasBuyItems, hasRentItems)
    );
  }

  private hasDistributionTypeToBeSwitchedToBuy(hasBuyItems: boolean, hasRentItems: boolean): boolean {
    return this.distributionType === DistributionTypeEnum.rent && !hasRentItems && hasBuyItems;
  }

  private initLoadedValue(value: UserObjectPagination): boolean {
    this.addContactRequestsToUserObjects(value);
    return value?.items?.length > 0;
  }
}
