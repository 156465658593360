import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SmartDesignAngularModule } from '@smartdesign/angular';
import { CoreModule } from '@core/core.module';
import { SharedModule } from '@shared/shared.module';
import { ActivationItemComponent } from './components/activation-item/activation-item.component';
import { CancelSubscriptionComponent } from './components/cancel-subscription/cancel-subscription.component';
import { ChangeBookingItemComponent } from './components/change-booking-item/change-booking-item.component';
import { DeleteItemComponent } from './components/delete-item/delete-item.component';
import { EditContentItemComponent } from './components/edit-content-item/edit-content-item.component';
import { ExtendRuntimeComponent } from './components/extend-runtime/extend-runtime.component';
import { OpenPreviewComponent } from './components/open-preview/open-preview.component';
import { EditModalComponent } from './edit-modal.component';


@NgModule({
  declarations: [
    EditModalComponent,
    OpenPreviewComponent,
    ExtendRuntimeComponent,
    EditContentItemComponent,
    DeleteItemComponent,
    ChangeBookingItemComponent,
    ActivationItemComponent,
    CancelSubscriptionComponent
  ],
  exports: [EditModalComponent],
  imports: [CommonModule, SmartDesignAngularModule, CoreModule, SharedModule]
})
export class EditModalModule {}
