import { Component } from '@angular/core';
import { DatadogService } from '@core/services/datadog.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  public readonly appName = 'owner-dashboard-ui';
  public isPreview: boolean;

  constructor(private readonly datadogService: DatadogService) {
    this.datadogService.init();
  }
}
