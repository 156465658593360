import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertText } from '../../../../../../core/models/enums/alert-key.enum';
import { Icon } from '../../../../../../core/models/enums/icon.enum';

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss']
})
export class ConfirmationModalComponent {
  public readonly Icon = Icon;
  public readonly AlertText = AlertText;

  @Input()
  public headline: string;

  @Input()
  public cancelButtonText = 'Abbrechen';

  @Input()
  public confirmButtonText: string;

  @Input()
  public isConfirmButtonRed = false;

  @Input()
  public confirmButtonDisabled = false;

  @Input()
  public buttonsDisabled = false;

  @Input()
  public alertText: AlertText | undefined = undefined;

  @Output()
  public readonly closeModal = new EventEmitter<void>();

  @Output()
  public readonly cancel = new EventEmitter<void>();

  @Output()
  public readonly confirm = new EventEmitter<void>();
}
