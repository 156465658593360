<div class="alertbox alertbox--important alertbox--is-shown alertbox--error alertbox--is-fixed">
    <div class="wrapper">
        <div class="alertbox__wrapper">
            <div class="alertbox__icon">
                <span class="sd-icon">exclamation_triangle</span>
            </div>
            <div class="alertbox__text">
                <p>{{fixedAlertText}}</p>
            </div>
            <div class="alertbox__icon-close">
                <button type="button" class="icon-btn icon-btn--50 is-right">
                    <span class="sd-icon">close</span>
                </button>
            </div>
        </div>
    </div>