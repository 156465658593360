import { Component, Input } from '@angular/core';
import { OnTopProductEnum } from '@core/models/enums/on-top-product.enum';
import { UserObject } from '@core/models/user-object.interface';
import { TealiumService } from '@core/services/tealium.service';
import { UrlService } from '@core/services/url.service';

@Component({
  selector: 'app-change-booking-item',
  templateUrl: './change-booking-item.component.html',
  styleUrls: ['./change-booking-item.component.scss']
})
export class ChangeBookingItemComponent {
  public isSingleRoomInApartment: boolean;
  public bookingSubtext: string;

  @Input()
  public set userObject(value: UserObject){
    this.isSingleRoomInApartment = value.isSingleRoomInApartment;
    this.bookingSubtext = this.getOnTopProductLabelText(value.onTopProducts ?? []);
  }

  constructor(private readonly tealiumService: TealiumService,
    private readonly urlService: UrlService) { }

  private getOnTopProductLabelText(onTopProducts: OnTopProductEnum[]): string {
    const hasTopImmo = onTopProducts.includes(OnTopProductEnum.topImmoRankingCategory);

    if (hasTopImmo) {
      return 'Top-Immo-Ranking gebucht';
    }

    return 'Anzeige aufwerten';
  }

  public jumpToChangeBooking(): void{
    this.tealiumLink();
    const originalBookingUrl = 'https://www.immowelt.de/myiw/immobilien/spezial/index.aspx';
    this.urlService.navigateToExternalUrlOnStage(originalBookingUrl);
  }

  private tealiumLink(): void {
    this.tealiumService.link({
      eventCategory: 'eigentuemerwelt',
      eventAction: 'click',
      eventLabel: 'anzeige bearbeiten_buchung aendern'
    });
  }

}
