import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of, Subject, throwError } from 'rxjs';
import { catchError, first, tap } from 'rxjs/operators';

import { UserObjectPagination } from '../models/user-object-pagination.interface';
import { UserObject } from '../models/user-object.interface';
import { DistributionTypeEnum } from './../models/enums/distribution-type.enum';
import { DistributionTypeService } from './distribution-type.service';
import { LoggingService } from './logging.service';

@Injectable({ providedIn: 'root' })
export class UserObjectService {
  private readonly listingLimit = 5;
  private readonly userObjectPaginationCache = new Map<string, UserObjectPagination>();
  private readonly userObjectPagination$$ = new Subject<UserObjectPagination>();
  public readonly userObjectPagination$ = this.userObjectPagination$$.asObservable();

  constructor(
    private readonly httpClient: HttpClient,
    private readonly loggingService: LoggingService,
    private readonly distributionTypeService: DistributionTypeService
  ) {
  }

  /**
   * Loads objects from the owner-dashboard service by the distribution type.
   *
   * @param distributionType The distribution type of the objects that should be loaded
   * @param sendEvent If an event to all subscribers should be sent or not
   * @returns Returns the objects as an observable either from the API or from the cache
   *          and also sends an event to all subscribers to this service if enabled
   */
  public loadUserObjectsFromService(distributionType: DistributionTypeEnum, sendEvent = true): Observable<UserObjectPagination | unknown> {
    if (this.userObjectPaginationCache.has(distributionType)) {
      const userObjectPagination = this.userObjectPaginationCache.get(distributionType);
      if (userObjectPagination) {
        userObjectPagination.fromCache = true;
      }
      this.loggingService.info(`Loading objects with ${distributionType} from cache`, userObjectPagination);
      if (sendEvent) {
        this.userObjectPagination$$.next(userObjectPagination);
      }
      return of(userObjectPagination);
    }

    return this.loadFirstListings(distributionType, sendEvent);
  }

  private loadFirstListings(distributionType: DistributionTypeEnum, sendEvent: boolean): Observable<UserObjectPagination>{

    return this.httpClient
    // eslint-disable-next-line max-len
    .get<UserObjectPagination>(`${environment.cloudGatewayUrl}owner-dashboard/v1/user/object?limit=${this.listingLimit}&distributionType=${distributionType}`)
    .pipe(
      tap((value) => {
        this.loggingService.info(`Loading objects with ${distributionType} from api`, value);
        value.fromCache = false;
        this.userObjectPaginationCache.set(distributionType, value);
        if (sendEvent) {
          this.userObjectPagination$$.next(value);
        }
      }),
      catchError((e) => {
        if (e instanceof HttpErrorResponse && e.status === 404) {
          const defaultUserObjectPagination: UserObjectPagination = { items: [], fromCache: false };
          this.loggingService.info(`Loading objects with ${distributionType} from api`, defaultUserObjectPagination);
          if (sendEvent) {
            this.userObjectPagination$$.next(defaultUserObjectPagination);
          }
          return of(defaultUserObjectPagination);
        }
        return throwError(e);
      })
    );
  }

  public loadMoreListings(): void {
    const distributionType = this.distributionTypeService.get();
    const userObjectPagination = this.userObjectPaginationCache.get(distributionType);
    if (!userObjectPagination || userObjectPagination.next === undefined) {
      return;
    }
    this.httpClient.get<UserObjectPagination>(`${environment.cloudGatewayUrl}owner-dashboard/${userObjectPagination.next}`)
      .subscribe(moreListings => {
        moreListings.items = userObjectPagination.items.concat(moreListings.items);
        moreListings.fromCache = false;
        this.userObjectPaginationCache.set(distributionType, moreListings);
        this.updateCurrentlyDisplayedListingWithCachedData();
      });
  }

  private updateCurrentlyDisplayedListingWithCachedData(): void {
    const distributionType = this.distributionTypeService.get();
    const userObjectPagination = this.userObjectPaginationCache.get(distributionType);
    this.userObjectPagination$$.next(userObjectPagination);
  }

  /**
   * Deletes the item from the cached RENT or BUY item list
   *
   * @param item The item that needs to be deleted
   */
  public removeObjectFromCaches(item: UserObject): void {
    this.removeObjectFromCache(DistributionTypeEnum.rent, item.globalObjectKey);
    this.fillUpListingsIfNeeded(DistributionTypeEnum.rent);

    this.removeObjectFromCache(DistributionTypeEnum.buy, item.globalObjectKey);
    this.fillUpListingsIfNeeded(DistributionTypeEnum.buy);
  }

  private removeObjectFromCache(distributionType: DistributionTypeEnum, gokOfObjectToRemove: string): void {
    const userObjectPagination = this.userObjectPaginationCache.get(distributionType) as UserObjectPagination;
    const userObjectToRemoveExistsInCache =
      userObjectPagination?.items.some((item: UserObject) => item.globalObjectKey === gokOfObjectToRemove);

    if (userObjectToRemoveExistsInCache) {
      userObjectPagination.items = userObjectPagination.items.filter(item => item.globalObjectKey !== gokOfObjectToRemove);
      this.userObjectPagination$$.next(userObjectPagination);
    }
  }

  private fillUpListingsIfNeeded(distributionType: DistributionTypeEnum): void {
    const userObjectPagination = this.userObjectPaginationCache.get(distributionType) as UserObjectPagination;
    if (userObjectPagination) {
      const amountOfLoadedItems = userObjectPagination.items.length;
      const moreItemsAvailable  = userObjectPagination.next !== undefined;
      const canMoreItemsBeLoaded = amountOfLoadedItems < this.listingLimit && moreItemsAvailable;
      if(canMoreItemsBeLoaded){
        this.loadFirstListings(distributionType, false).pipe(
          first()
        ).subscribe(() => {
          this.updateCurrentlyDisplayedListingWithCachedData();
        });
      }
    }
  }
}
