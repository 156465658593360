<app-confirmation-modal
    [headline]="'Anzeige deaktivieren'"
    [confirmButtonText]="'Jetzt deaktivieren'"
    [alertText]="alertText"
    [buttonsDisabled]="deactivationInProgress"
    (closeModal)="closeModalEvent.emit()"
    (cancel)="closeModalEvent.emit()"
    (confirm)="onClickDeactivateObject()">
    Hast du jemanden für deine WG gefunden? Großartig! Deaktiviere einfach deine Anzeige. Du kannst sie bei Bedarf jederzeit wieder für 8 Wochen kostenlos aktivieren.
</app-confirmation-modal>
