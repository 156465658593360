import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { BadgeColorPipe } from './pipes/badge-color.pipe';
import { IsStringPipe } from './pipes/is-string.pipe';
import { KeepHtmlPipe } from './pipes/keep-html.pipe';
import { ObjectStatusTextPipe } from './pipes/object-status-text.pipe';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { StageUrlPipe } from './pipes/stage-url.pipe';

@NgModule({
  imports: [CommonModule],
  exports: [CommonModule, KeepHtmlPipe, ObjectStatusTextPipe, BadgeColorPipe, IsStringPipe, StageUrlPipe, SafeHtmlPipe],
  providers: [],
  declarations: [KeepHtmlPipe, ObjectStatusTextPipe, BadgeColorPipe, IsStringPipe, StageUrlPipe, SafeHtmlPipe]
})
export class CoreModule {}
