<div class="price-table" data-cy="pricetable">
  <div class="button-group button-group--size-50">
    <button class="button-group__button" (click)="showRent()" data-cy="rent-modal"
      [ngClass]="{'is-active is-active-button' : switchModalContent === 'rent' }">
      <div class="button-group__text" data-cy="pricetable-nav-rent">Vermieten</div>
    </button>
    <button class="button-group__button" (click)="showSale()" data-cy="sale-modal"
      [ngClass]="{'is-active is-active-button' : switchModalContent === 'sale' }">
      <div class="button-group__text" data-cy="pricetable-nav-sell">Verkaufen</div>
    </button>
  </div>
  <div [innerHTML]="priceRentContent | keepHtml" *ngIf='switchModalContent === "rent"'></div>
  <div [innerHTML]="priceSaleContent | keepHtml" *ngIf='switchModalContent === "sale"'></div>
  <p class="has-font-200 is-bold" data-cy="secure-pay-text">Einfach und sicher bezahlen</p>
  <img class="zahlungsarten" src="https://cdnglobal.immowelt.org/price-overview/logos_zahlungsarten.svg">
</div>
