import { Injectable } from '@angular/core';
import { ScriptService } from '@core/services/script.service';
import { environment } from '@environments/environment';
import { NavigationUiTypeEnum } from '@core/models/enums/navigation-ui-type.enum';

@Injectable({
  providedIn: 'root'
})
export class NavigationUiService {
  private isLive: boolean;

  constructor(private readonly script: ScriptService) {
    this.isLive = environment.stage === 'live';
  }

  public setNavigationType(type: NavigationUiTypeEnum): void {
    let stage = '';
    if (!this.isLive) {
      stage = `-${environment.stage}`;
    }
    this.script.addScript(`https://navigation${stage}.immowelt.org/v1/${type.toUpperCase()}/0/navigation.js`, 'body');
  }
}
