import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { LoggingService } from './logging.service';

@Injectable({
  providedIn: 'root'
})
export class UrlService {
  constructor(@Inject(DOCUMENT) private readonly document: Document,
  private readonly loggingService: LoggingService) {}


  public navigateToExternalUrlOnStage(externalUrl: string): void{
    const stageUrl = this.setStageForUrl(externalUrl);
    this.navigateToExternalUrl(stageUrl);
  }

  public navigateToExternalUrl(externalUrl: string): void{
    const window = this.document.defaultView;
    if(!window){
      this.loggingService.error('Window object not available. Cant jump to url:', externalUrl);
      return;
    }

    window.location.href = externalUrl;
  }

  public setStageForUrl(url: string) {
    if (!url) {
      return '';
    }

    if (environment.stage === 'dev' || environment.stage === 'preview') {
      url = url.replace('www', environment.stage);
      if (url.indexOf('signin.immowelt.de') !== -1) {
        url = url.replace('signin.immowelt.de', `${environment.stage}-signin.immowelt.de`);
      } else if (url.indexOf('objektanfragen.immowelt.de') !== -1) {
        url = url
        .replace('objektanfragen.immowelt.de', environment.stage + '-objektanfragen.immowelt.de');
        // .replace('/0-558/', `/${environment.objektanfragenUrlPart}/`);
      }
    }
    return url;
  }
}
