<div class="flex-container">
  <div class="flex-item-card">
    <div class="card">
      <div class="row">
        <div class="col col-400-12 col-500-6">
          <div class="cell">
            <div class="cell__row cell-size-50" tabindex="0">
              <div class="cell__col">
                <span class="has-font-75" data-cy="calls">Aufrufe</span>
                <div [ngClass]="{'badge--important': item.averageOfObjectCalls === -1}" class="badge badge--counter badge--100 badge--secondary">
                  <div *ngIf="item.averageOfObjectCalls === undefined" class="loading-dots">
                    <span class="sr-only">Loading</span>
                    <span class="loading__dot"></span>
                    <span class="loading__dot"></span>
                    <span class="loading__dot"></span>
                  </div>
                  <div *ngIf="item.averageOfObjectCalls === -1">
                    <span class="sd-icon">close</span>
                  </div>
                  <div *ngIf="item.averageOfObjectCalls !== undefined && item.averageOfObjectCalls >= 0">
                    {{item.averageOfObjectCalls | number:'1.0-0':'de'}}
                  </div>
                </div>
              </div>
              <div class="cell__col">
                <span class="has-font-75" data-cy="requests">Anfragen</span>
                <div [ngClass]="{'badge--important': item.contactRequestInboxItem?.total === -1}" class="badge badge--counter badge--100 badge--secondary">
                  <div *ngIf="!item.contactRequestInboxItem" class="loading-dots">
                    <span class="sr-only">Loading</span>
                    <span class="loading__dot"></span>
                    <span class="loading__dot"></span>
                    <span class="loading__dot"></span>
                  </div>
                  <div *ngIf="item.contactRequestInboxItem?.total === -1">
                    <span class="sd-icon">close</span>
                  </div>
                  <div *ngIf="item.contactRequestInboxItem && item.contactRequestInboxItem.total >= 0">
                    {{item.contactRequestInboxItem?.total | number:'1.0-0':'de'}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col col-400-12 col-500-6">
          <div class="cell has-divider--inset" data-cy="statistics-link">
            <a [href]="statisticLink | stageUrl" (click)="tealiumLink('statistic')">
              <div class="cell__row cell-size-50 cell--clickable" tabindex="0">
                <div class="cell__col" data-cy="icon-statistics">
                    <i class="sd-icon">statistics</i>
                </div>
                <div class="cell__col has-max-width" data-cy="statistic-object">Statistik deines Objekts</div>
                <div class="cell__col">
                    <i class="sd-icon">angle_right</i>
                </div>
              </div>
            </a>
          </div>
          <div class="cell" data-cy="inbox-link">
            <a [href]="inboxLink | stageUrl" (click)="tealiumLink('contact-inbox')">
              <div class="cell__row cell-size-50 cell--clickable" tabindex="0">
                <div class="cell__col" data-cy="inbox-icon">
                    <i class="sd-icon">email</i>
                </div>
                <div class="cell__col has-max-width" data-cy="inbox-for-object">Posteingang</div>
                <div class="cell__col">
                    <i class="sd-icon">angle_right</i>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
