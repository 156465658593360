<div *ngIf="activationTextCaption"
      class="cell__row cell-size-300 cell--clickable"
      data-cy="activate-ad"
      tabindex="0"
      (click)="onClickActivate()">
    <div class="cell__col has-max-width">
        <p  class="has-font-100"
            data-cy="activate-caption-text">
            {{ activationTextCaption }}
        </p>
        <app-runtime-text [item]="userObject">
        </app-runtime-text>
    </div>
    <div class="cell__col">
        <i class="sd-icon">
            angle_right
        </i>
    </div>
</div>
