import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from '@angular/animations';
import {
    AfterViewInit,
    Component,
    ContentChildren,
    Directive,
    ElementRef,
    Input,
    QueryList,
    TemplateRef,
    ViewChild,
    ViewChildren
} from '@angular/core';

@Directive({
  selector: '[appSliderItem]'
})
export class SliderItemDirective {
  constructor(public tpl: TemplateRef<any>) {}
}

@Directive({
  /* tslint:disable-next-line:directive-selector */
  /*eslint-disable */
  selector: '.slider-item'
  /*eslint-enable */
})
export class SliderItemElementDirective {}

@Component({
  selector: 'app-slider',
  exportAs: 'slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements AfterViewInit {
  @ContentChildren(SliderItemDirective) items: QueryList<SliderItemDirective>;
  @Input() timing = '250ms ease-in';
  @Input() showControls = true;
  @ViewChildren(SliderItemElementDirective, { read: ElementRef }) private itemsElements: QueryList<ElementRef>;
  @ViewChild('slider') private slider: ElementRef;
  player: AnimationPlayer;
  itemWidth: number;
  currentSlide = 0;
  sliderWrapperStyle = {};
  resizeTimeout: number;
  forwardDisabled = false;
  backwardDisabled = true;
  dotItems: Array<any> = [];

  constructor(private builder: AnimationBuilder) {}

  ngAfterViewInit() {
    // For some reason only here I need to add setTimeout, in my local env it's working without this.
    setTimeout(() => {
      this.itemWidth = this.itemsElements.first.nativeElement.getBoundingClientRect().width;
      this.sliderWrapperStyle = {
        width: `${this.itemWidth * 2}px`
      };
      this.reset(this.items.toArray());
    });
  }

  public reset(items: any[]) {
    this.currentSlide = 0;
    this.dotItems = [];
    const dotCount = Math.ceil(items.length / 2);
    for (let i = 0; i < dotCount; i++) {
      this.dotItems.push({});
    }

    this.showControls = dotCount > 1;

    const myAnimation: AnimationFactory = this.buildAnimation(0);
    this.player = myAnimation.create(this.slider.nativeElement);
    this.player.play();
  }

  public next() {
    if (this.currentSlide + 1 === this.dotItems.length) {
      return;
    }

    this.currentSlide = (this.currentSlide + 1) % this.dotItems.length;
    const offset = this.currentSlide * this.itemWidth;
    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.slider.nativeElement);
    this.player.play();
  }

  public prev() {
    if (this.currentSlide === 0) {
      return;
    }

    this.currentSlide = (this.currentSlide - 1 + this.dotItems.length) % this.dotItems.length;
    const offset = this.currentSlide * this.itemWidth;

    const myAnimation: AnimationFactory = this.buildAnimation(offset);
    this.player = myAnimation.create(this.slider.nativeElement);
    this.player.play();
  }

  private buildAnimation(offset: number) {
    return this.builder.build([animate(this.timing, style({ transform: `translateX(-${offset}px)` }))]);
  }
}
