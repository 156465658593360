import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { DistributionTypeEnum } from '@core/models/enums/distribution-type.enum';
import { DistributionTypeService } from '@core/services/distribution-type.service';
import { TealiumService } from '@core/services/tealium.service';

@Component({
  selector: 'app-tabs',
  templateUrl: './tabs.component.html',
  styleUrls: ['./tabs.component.scss']
})
export class TabsComponent implements OnInit {
  public distributionType: string;

  public readonly distributionTypeEnum = DistributionTypeEnum;

  constructor(
    private location: Location,
    private readonly distributionTypeService: DistributionTypeService,
    private readonly tealiumService: TealiumService
  ) {}

  ngOnInit(): void {
    this.distributionTypeService.getAsObservable().subscribe((distributionType) => {
      this.distributionType = distributionType;
    });

    this.distributionType = this.distributionTypeService.get();
    // do routing only if Service is not initialized
    if (!this.distributionTypeService.isInitialized) {
      this.changeRoute();
    }
  }

  public changeDistributionType(distributionType: DistributionTypeEnum) {
    if (this.distributionType === distributionType) {
      return;
    }

    this.distributionType = distributionType;
    this.changeRoute();

    this.distributionTypeService.set(distributionType);
  }

  private changeRoute() {
    if (this.distributionType === DistributionTypeEnum.rent) {
      this.location.go('/vermieten');
    } else if (this.distributionType === DistributionTypeEnum.buy) {
      this.location.go('/verkaufen');
    }
    setTimeout(() => {
      this.tealiumService.view();
    }, 1000);
  }
}
