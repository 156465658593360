import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AlertText } from '@core/models/enums/alert-key.enum';
import { UserObject } from '@core/models/user-object.interface';
import { ObjectService } from '@core/services/object.service';
import { UserObjectService } from '@core/services/user-object.service';
import { finalize } from 'rxjs/operators';

@Component({
  selector: 'app-delete-object-content',
  templateUrl: './delete-object-content.component.html',
  styleUrls: ['./delete-object-content.component.scss']
})
export class DeleteObjectContentComponent {
  public alertText: AlertText | undefined = undefined;
  public deletionInProgress = false;

  @Input()
  public userObject: UserObject;

  @Output()
  public readonly closeModalEvent = new EventEmitter<any>();

  constructor(
    private readonly objectService: ObjectService,
    private readonly userObjectService: UserObjectService
  ) { }

  public deleteObject(): void {
    if (this.deletionInProgress) {
      return;
    }
    this.deletionInProgress = true;
    this.deleteCall();
  }

  private deleteCall(): void {
    this.objectService
      .deleteObject(this.userObject.globalObjectKey)
      .pipe(
        finalize(() => {
          this.deletionInProgress = false;
        })
      )
      .subscribe(success => {
        this.alertText = success ? undefined : AlertText.DeletionError;
        if (success) {
          this.userObjectService.removeObjectFromCaches(this.userObject);
          this.deletionInProgress = false;
          this.closeModalEvent.emit();
        }
      });
  }
}
