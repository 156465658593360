<div class="object" [attr.data-gok]="item.globalObjectKey">
  <div class="object__item">
    <div class="object__image" data-cy="listing-image" (click)="openPreview()">
      <svg [ngClass]="{'object__image--has-border' : hasTopImmo}" *ngIf="!item.imageUrl" viewBox="0 0 328 224" fill="none" xmlns="http://www.w3.org/2000/svg" data-cy="has-yellow-border">
        <path fill-rule="evenodd" clip-rule="evenodd"
          d="M-0.643742 -4.87101L-1.45432 168.931L45.2367 108.439C52.7776 98.7412 67.1905 98.8358 74.7397 108.368L105.821 148.295C112.212 156.33 124.503 156.463 130.911 148.366L204.469 56.1773C211.424 47.4817 224.561 47.2897 231.72 55.8064L328 170.34L328.811 -3.46234C328.929 -9.72487 323.879 -14.7778 317.682 -14.7273L10.6212 -16.0002C4.35864 -16.119 -0.694298 -11.0685 -0.643742 -4.87101ZM116.518 84.3348C134.199 84.1932 148.486 69.9908 148.344 52.3104C148.371 34.5635 134 20.3433 116.32 20.4849C98.6392 20.6265 84.3525 34.8289 84.4941 52.5093C84.6357 70.1897 98.838 84.4764 116.518 84.3348Z"
          fill="#757584"></path>
      </svg>
      <img
        *ngIf="item.imageUrl"
        alt=""
        id="objectImage{{item.onlineId}}"
        [ngClass]="{'object__image--has-border' : hasTopImmo}"
        src="{{item.imageUrl}}"/>
      <div class="object__image__header" *ngIf="!item.visible"></div>
      <div class="badge badge--text badge-position" [ngClass]="item.activationRequest | badgeColor:item.visible" [innerText]="item.activationRequest | objectStatusText:item.visible">
      </div>
      <div class="preview-link preview-link-position" *ngIf="!item.visible">
        <span>Vorschau</span>
        <span class="ml-50" sdUiIcon>eye</span>
      </div>
      <div class="object__on-top-position">
        <span class="has-font-75 is-bold mr-75" data-cy="hasAdditionalProduct">{{onTopProduct}}</span>
        <div *ngIf="hasTopImmo" class="badge badge--icon badge--primary" data-cy="topImmoBadge">
          <span class="sd-icon">angle_up_ranking</span>
        </div>
      </div>
    </div>
    <div class="object__facts">
      <span class="object__headline is-bold" [innerHtml]="item.title | safeHtml"></span>
      <div class="object__icon-wrapper">
        <i class="sd-icon" id="cy-locationicon">location</i>
        <span class="object__location" [innerHtml]="(item.zip ? item.zip + ' ' : '') + item.city | safeHtml"></span>
      </div>
      <div class="object__icon-wrapper">
        <i class="sd-icon" id="cy-checkicon">check</i>
        <span class="object__id" data-cy="online-id" >Online-ID: {{item.onlineId}}</span>
      </div>
      <div class="object__hardfacts">
        <span class="object__price is-bold" *ngIf="item.price">{{item.price | number:'1.0':'de'}}
          {{item.currency}}</span>
        <span class="object__size" *ngIf="item.space">{{item.space}} m²</span>
        <span class="object__rooms" *ngIf="item.room">{{item.room | number:'1.0':'de'}} Zi.</span>
      </div>
    </div>
  </div>
  <div class="object__divider"></div>
  <div class="flex-wrapper-mobile">
    <app-info-card [item]="item"></app-info-card>
    <div *ngIf="item.subscription && item.visible">
      <div class="object__divider"></div>
      <div class="flex-container">
        <div class="flex-item-cancel-text">
          <app-cancel-text [item]="item"></app-cancel-text>
        </div>
        <div class="flex-item-cancel-link">
          <a class="link link--primary" (click)="onModalOpen()" *ngIf="item.subscription && item.visible" data-cy="cancel-extension-button">Vertrag beenden</a>
        </div>
      </div>
    </div>
    <app-alertbox-fixed *ngIf="generalError" alertKey="GENERAL_ERROR"></app-alertbox-fixed>
  </div>
  <div class="object__divider"></div>
  <div class="flex-container">
    <div class="flex-item-button">
      <app-edit-button *ngIf="!item.activationRequest; else elseBlock"
                        [userObject]="item">
      </app-edit-button>
    </div>
    <ng-template #elseBlock>
      <div class="object__info" data-cy="activation-request">
        Während dein Objekt in Prüfung ist, kann es nicht aktiviert oder bearbeitet werden.
      </div>
    </ng-template>
  </div>
</div>
