import { Component, OnInit } from '@angular/core';
import { IeService } from '@core/services/ie.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'app-alertbox-ie',
  templateUrl: './alertbox-ie.component.html',
  styleUrls: ['./alertbox-ie.component.scss']
})
export class AlertboxIeComponent implements OnInit {
  public isIe: boolean;
  logoChromeUrl: string;
  logoFirefoxUrl: string;

  constructor(private ieService: IeService) {}

  ngOnInit(): void {
    this.logoChromeUrl = environment.assetsUrl + 'assets/logo_chrome.png';
    this.logoFirefoxUrl = environment.assetsUrl + 'assets/logo_firefox.png';
    this.isIe = this.ieService.isIe();
  }
}
