import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '@environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private headers: HttpHeaders;
  private isLive: boolean;
  private authTokenUrl: string;

  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders();
    this.headers = this.headers.append('Authorization', environment.authentication.basicAuthHeaderValue);
    this.authTokenUrl = environment.gatewayUrl.replace(/\/$/, '') + environment.authentication.tokenResourceName.replace(/\/$/, '');
    this.isLive = environment.stage === 'live';
  }

  getTokens(username: string, password: string) {
    if (this.isLive) {
      throw new Error('getTokens() is not supported for live!');
    }

    const body = new FormData();
    body.append('grant_type', 'password');
    body.append('username', username);
    body.append('password', password);
    return this.http.post(this.authTokenUrl, body, { headers: this.headers });
  }
}
