import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthenticationGuard } from '@core/guards/authentication.guard';
import { AutomatedTestGuard } from '@core/guards/automated-test.guard';
import { PreventBackNavigationGuard } from '@core/guards/prevent-back-navigation.guard';
import { AutomatedTestComponent } from './pages/automated-test/automated-test.component';
import { HomeComponent } from './pages/home/home.component';
import { ObjectPreviewComponent } from './pages/object-preview/object-preview.component';
import { ErrorPageComponent } from '@shared/components/error-page/error-page.component';

const routes: Routes = [
  {
    path: 'automated-test',
    component: AutomatedTestComponent,
    canActivate: [AutomatedTestGuard],
    canDeactivate: [PreventBackNavigationGuard]
  },
  {
    path: 'vermieten',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [PreventBackNavigationGuard]
  },
  {
    path: 'verkaufen',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [PreventBackNavigationGuard]
  },
  {
    path: 'preview/:gok',
    component: ObjectPreviewComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [PreventBackNavigationGuard]
  },
  {
    path: 'error/:type',
    component: ErrorPageComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [PreventBackNavigationGuard]
  },
  {
    path: '',
    component: HomeComponent,
    canActivate: [AuthenticationGuard],
    canDeactivate: [PreventBackNavigationGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
