<div class="modal__container">
    <div class="modal__title">
        <h2 class="modal__title-text" data-cy="headline-modal">Anzeige bearbeiten</h2>
        <button type="button" class="icon-btn icon-btn--50 modal__close" (click)="closeModal.emit()" data-cy="close-modal-button">
            <span class="sd-icon">close</span>
        </button>
    </div>
    <div class="modal__content">
        <div class="modal__content-inner">
            <div class="cell has-divider" >

                <app-activation-item [userObject]="userObject">
                </app-activation-item>

                <app-cancel-subscription [userObject]="userObject">
                </app-cancel-subscription>

                <app-extend-runtime [userObject]="userObject">
                </app-extend-runtime>

                <app-edit-content-item [globalObjectKey]="userObject.globalObjectKey">
                </app-edit-content-item>

                <app-change-booking-item [userObject]="userObject">
                </app-change-booking-item>

                <app-open-preview [userObject]="userObject"></app-open-preview>

                <app-delete-item></app-delete-item>
            </div>
        </div>
    </div>
</div>
