import { Component, Input, OnInit } from '@angular/core';
import { OnTopProductEnum } from '@core/models/enums/on-top-product.enum';
import { UserObject } from '@core/models/user-object.interface';
import { TealiumService } from '@core/services/tealium.service';
import { environment } from '@environments/environment';

import { PreviewLinkService } from '../../../../object-preview/services/preview-link.service';
import { ModalContainerService } from '../../modal-container/modal-container.service';

@Component({
  selector: 'app-object',
  templateUrl: './object.component.html',
  styleUrls: ['./object.component.scss']
})
export class ObjectComponent implements OnInit {
  @Input()
  public item: UserObject;
  public generalError: boolean;
  public hasTopImmo: boolean;
  public onTopProduct: string;

  constructor(
    private readonly modalContainerService: ModalContainerService,
    private readonly tealiumService: TealiumService,
    private readonly previewLinkService: PreviewLinkService,
  ) {}

  public ngOnInit(): void {
    if (this.item.imageUrl && !this.item.imageUrl.startsWith(environment.imageBaseUrl)) {
      this.item.imageUrl = environment.imageBaseUrl.replace(/\/$/, '') + this.item.imageUrl + '/320x220';
    }

    if (this.item.currency === 'EUR') {
      this.item.currency = '€';
    }

    this.hasTopImmo = this.item?.onTopProducts?.includes(OnTopProductEnum.topImmoRankingCategory);

    if (this.item?.onTopProducts?.length > 1) {
      this.onTopProduct = 'Zusatzprodukte gebucht';
    } else if (this.hasTopImmo) {
      this.onTopProduct = 'Zusatzprodukt gebucht';
    } else {
      this.onTopProduct = '';
    }
  }

  public onModalOpen(): void {
    this.tealiumService.link({ eventCategory: 'eigentuemerwelt', eventAction: 'click', eventLabel: 'verlaengerung beenden' });
    this.modalContainerService.openCancelModal(this.item);
  }

  public openPreview(): void {
    this.previewLinkService.openPreview(this.item);
  }
}
