import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PreviewAlertService {

  public readonly visibility: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public readonly isVisible = this.visibility.asObservable();

  public setVisibility(status: boolean): void {
    this.visibility.next(status);
  }
}
