import { ErrorHandler, Injectable } from '@angular/core';
import { DatadogService } from '../services/datadog.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  constructor(private readonly datadogService: DatadogService) {}

  handleError(error: string) {
    this.datadogService.logError(error);
    console.error(error);
  }
}
